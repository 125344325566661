import React from 'react';
import Timestamp from 'react-timestamp';
import { useHelpers } from '../../../Hooks/helpers';

const OfferwallHistoryItem = ({ data }) => {

    const helpers = useHelpers();

    return (
        <tr>
            <td>{data.name}</td>
            <td>{data.task_name}</td>
            <td>{data.virtual_amount}</td>
            <td className='font-bold flex items-center justify-center space-x-1'><helpers.AmountToCurrency amount={data.amount*1} type='cash' iconSize={19} /></td>
            <td>{data.transaction_id}</td>
            <td className='flex justify-end'><helpers.TimestampAndData created={data.created} /></td>
        </tr>
    )
}

export default React.memo(OfferwallHistoryItem);