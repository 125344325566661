import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Alert } from '@material-tailwind/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../Utility/Utility';
import OtpInput from 'react-otp-input';
import { SmallPrimaryButton } from '../../../Utility/Buttons';
import { ArrowRightCircle } from 'react-feather';
import { useInfoModal } from '../../../Hooks/infoModal';
import { InfoModal } from '../../modals/InfoModal';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from '../../../Hooks/callAPI';
import { toast } from 'react-hot-toast';
import { Button } from '@material-tailwind/react';

const VerifyEmail = ({ auth }) => {

    const infoModal = useInfoModal();
    const callAPI = useCallAPI();
    
    const [otp, setOtp] = useState('');
    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    const sendEmailOTP = async () => {

        setBlockDiv(true);
        const res = await callAPI("sendEmailOTP");

        if(res.data.state === 1) { //Success
            toast.success('Please check your email for OTP');
            await auth?.getAppBootstrap();
            setBlockDiv(false);
        } else { //Handle error
            setBlockDiv(false);
            toast.error(res.data.error);
            console.log(res.data);
        }

    }

    const verifyEmail = async () => {

        setBlockDiv(true);
        const formData = {
            otp: otp
        };

        const res = await callAPI("verifyEmail", formData);
        if(res.data.state === 1) { //Success
            await auth?.getAppBootstrap();
            setBlockDiv(false);
        } else { //Handle error
            setBlockDiv(false);
            res.data.formError && setFormError(res.data.formError);
            toast.error(res.data.error);
            console.log(res.data);
        }

    }
    
    return (
        <>
            {auth?.userBootstrap?.userData?.email_otp_requested*1 > 0 ? 
                <div>
                    <h3 className='text-lg text-primary font-viga font-bold leading-5 mb-2'>We have sent an OTP to your email address.</h3>
                    <p className='text-sm text-light font-ibm-plex-sans leading-4 mb-2'>Enter 4 digit OTP below</p>
                    <div className='mt-3'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} className='otpInput' />}
                        />
                    </div>
                    {formError.otp && <p className="text-red-400 text-xsm mt-2 text-left">{formError.otp}</p>}
                    <div className='mt-4'>
                        <SmallPrimaryButton type="submit" disabled={otp.length !== 4 || blockDiv ? 1 : 0} isloading={blockDiv ? 1 : 0} onClick={verifyEmail}>Submit OTP <ArrowRightCircle width={23} /></SmallPrimaryButton>
                    </div>
                    <div className='mt-3 flex justify-between items-center align-middle gap-4'>
                        <p className='text-xs text-light font-ibm-plex-sans'>Check your spam and junk folder for the OTP. If you haven't received the OTP, you may resend.</p>
                        <Button size='sm' color='blue' className='min-w-[130px]' disabled={blockDiv ? 1 : 0} loading={blockDiv} onClick={sendEmailOTP}>Resend OTP</Button>
                    </div>
                </div>
            : 
                <div className=''>
                    <h3 className='text-lg text-primary font-viga font-bold leading-5 mb-2'>Please verify your email to help us fight against spam. </h3>
                    <p className='text-sm text-light font-ibm-plex-sans leading-4 mb-2'>Click the button below to send an OTP to your registered email.</p>
                    <div className='mt-4'>
                        <SmallPrimaryButton type="submit" disabled={blockDiv ? 1 : 0} isloading={blockDiv ? 1 : 0} onClick={sendEmailOTP}>Start Verification <ArrowRightCircle width={23} /></SmallPrimaryButton>
                    </div>
                </div>
            }
            <InfoModal hook={infoModal} />
        </>
    );
};
export default VerifyEmail;