import { useState } from 'react';
import { useCallAPI } from './callAPI';
import { useHelpers } from './helpers';

export const useCallInfiniteResultsAPI = () => {
    
    const callAPI = useCallAPI();
    const helper = useHelpers();

    const [rawData, setRawData] = useState(false);
    const [data, setData] = useState(false);
    const [loadFull, setLoadFull] = useState(false);
    const [loading, setLoading] = useState(false);

    const getResults = async ({ apiURL, reset = false, sortByDate = true, options = false, sortKey = ["created"] }) => {
        
        setLoading(true);
        let offset = 0;
        if(!reset) {
            offset = rawData.length;
        }

        const res = await callAPI(apiURL + "/" + offset, options);
        if(res.status && res.data.length > 0) {

            //If add new set of data
            if(!reset) {
                const mergedArray = [...rawData, ...res.data];
                setRawData(mergedArray);
                const sortedArray = sortByDate ? helper.groupItemsByData(mergedArray, sortKey) : mergedArray;
                setData(sortedArray);
            } else {
                setRawData(res.data);
                const sortedArray = sortByDate ? helper.groupItemsByData(res.data, sortKey) : res.data;
                setData(sortedArray);
            }

            if(res.data.length < 10) {
                setLoadFull(true);
            } else {
                setLoadFull(false);
            }

        } else {
            (!rawData || reset) && setRawData([]);
            (!rawData || reset) && setData([]);
            setLoadFull(true);
        }
        setLoading(false);
        return;

    }

    return {data, loadFull, getResults, rawData, loading};

}