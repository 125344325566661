import { Button, Chip, IconButton } from '@material-tailwind/react';
import React from 'react';
import { AlertTriangle, CheckCircle, Loader } from 'react-feather';
import Timestamp from 'react-timestamp';
import { useHelpers } from '../../../Hooks/helpers';
import cashImg from '../../../Theme/assets/img/cash.png';
import headsImg from "../../../Theme/assets/img/heads.png";
import tailsImg from "../../../Theme/assets/img/tails.png";
import { useUserAuth } from '../../../Contexts/authContext';
import { HandThumbDownIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import appConfig from '../../../Utility/Config/config';

const CoiflipGameHistoryItem = ({ data }) => {

    const helpers = useHelpers();
    const auth = useUserAuth();
    const navigate = useNavigate();

    return (
        <tr>
            <td onClick={() => navigate('/coinflipGame/' + data.id)} className='cursor-pointer'>
                #{data.id}<br/>
                <span className='text-primary font-bold text-lg'><helpers.AmountToCurrency amount={data.entryFee} /></span>
            </td>
            <td>
                {auth?.userBootstrap?.userData?.username === data.callerUsername ? 'You' : data.callerUsername}<br/>
                <span className='flex justify-center my-2'>{data.callerCalled == '1' ? <img src={headsImg} className="w-[40px]" /> : <img src={tailsImg} className="w-[40px]" />}</span>
            </td>
            <td>
                {auth?.userBootstrap?.userData?.username === data.tosserUsername ? 'You' : data.tosserUsername}<br/>
                <span className='flex justify-center my-2'>{data.tosserCalled == '1' && <img src={headsImg} className="w-[40px]" />}{data.tosserCalled == '2' && <img src={tailsImg} className="w-[40px]" />}</span>
            </td>
            <td>
                <div className='flex justify-center'><helpers.TimestampAndData created={data.calledOn} /></div>
                <Chip className='mx-auto inline-block mt-1' value={<helpers.trimAddress address={data.callerHash} scanURL={appConfig.scanTxnLink + data.callerHash} copy />} />
            </td>
            <td>
                {data.tossedOn > 0 && 
                    <>
                        <div className='flex justify-center'><helpers.TimestampAndData created={data.tossedOn} /></div>
                        <Chip className='mx-auto inline-block mt-1' value={<helpers.trimAddress address={data.tosserHash} scanURL={appConfig.scanTxnLink + data.tosserHash} copy />} />
                    </>
                }
            </td>
            <td>
                {data.refunded === 'no' ? 
                    <>
                        {data.winner_id === null ?
                            <span className='border-2 border-dark text-xs rounded-10 px-3 py-1 inline-block animate-pulse'>Waiting<br/>Opponent</span>
                        :
                            <>
                                {auth?.userBootstrap?.userData?.id*1 === data.winner_id*1 ? 
                                    <span className='bg-gradient-six text-darker font-bold text-xs rounded-10 px-3 py-1 inline-block text-center'><TrophyIcon className='w-[25px] ml-auto mb-1' /> Won <helpers.AmountToCurrency amount={data.winAmount} decimals={1} /></span>
                                : 
                                    <span className='bg-gradient-four text-[#999] font-bold text-xs rounded-10 px-3 py-1 inline-block text-center'><HandThumbDownIcon className='w-[25px] ml-auto mb-1' /> You Lose</span>
                                }
                            </>
                        }
                    </>
                :
                    <span className='border-2 border-red-500 text-red-200 text-xs rounded-10 px-3 py-1 inline-block'>Cancelled</span>
                }
                
            </td>
        </tr>
    )
}

export default React.memo(CoiflipGameHistoryItem);