import { useState } from 'react';

export const useAppDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(false);

    const open = (userData) => {
        setData(userData);
        setIsOpen(true);
    }

    const close = () => {
        setIsOpen(false);
        setData(false);
    }

    return {
        isOpen,
        open,
        close,
        data
    };
}
