import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { ArrowRightCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import NeedHelp from '../../Components/auth/NeedHelp';
import WelcomeTitle from "../../Components/auth/WelcomeTitle";
import { useAppState } from '../../Contexts/appStateContext';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import loginBg from "../../Theme/assets/img/loginbg.jpg";
import { LargePrimaryButton } from "../../Utility/Buttons";
import appConfig from '../../Utility/Config/config';
import { FormInputBig } from '../../Utility/Utility';

const Register = () => {

    const callAPI = useCallAPI();
    const history = useNavigate();
    const authContext = useUserAuth();
    const params = useParams();
    const appState = useAppState();

    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    useEffect(() => {
        if(params.ref) {
            localStorage.setItem("refSalt", params.ref);
            setValue("refSalt", params.ref);
            toast.success("Referred by " + params.ref);
        } else {
            if(localStorage.getItem("refSalt") !== null && localStorage.getItem("refSalt") !== undefined) {
                toast.success("Referred by " + localStorage.getItem("refSalt"));
                setValue("refSalt", localStorage.getItem("refSalt"));
            } else {
                setValue("refSalt", "platform");
            }
        }
    }, []);

    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
            history('/dashboard');
        }
    }, [authContext]);

    const FormSchema = yup.object().shape({
        email: yup.string().min(5).max(100).label('Email Address').required('Please enter your email address').email('Please enter a valid email'),
        refSalt: yup.string().max(20).label('Referrer ID'),
        password: yup.string().min(4).max(50).required('Password is required'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid, isDirty }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const processRegistration = async () => {
        try {

            //Check here if user is already logged in
            if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
                history('/dashboard');
            }

            setBlockDiv(true);
            setFormError({});
            const res = await callAPI("register", getValues());
            if(res.data.state === 1) { //Success
                localStorage.setItem('userauthtoken', res.data.auth_token);
                await authContext.getAppBootstrap();
                history('/');
            } else { //Handle error
                res.data.formError && setFormError(res.data.formError);
                console.log(res.data);
                throw(res.data.error);
            }

        } catch (error) {
            toast.error(error);
        } finally {
            setBlockDiv(false);
        }
    }
    
    return (
        <main className="mb-[60px] md:mb-[75px]">
            <div className="px-4 md:px-11 lg:px-0 max-w-[1024px] mx-auto">
                <div className="py-12 pt-7">
                    <WelcomeTitle
                        title="Welcome to DexLoot"
                        text="Join the DexLoot gaming and earning community. Create your FREE account now."
                    />
                </div>
                <div 
                    className="md:grid grid-cols-2 items-center gap-6 lg:gap-0 space-y-10 md:space-y-0 max-w-md overflow-hidden mx-auto md:mx-0 md:max-w-none relative rounded-[30px] shadow-xl bg-cover border-[#1f1f1f] border-[4px]"
                    style={{ backgroundImage: `url(${loginBg})`, backgroundPosition: 'top center' }}
                >
                    <div className="space-y-10 bg-darkest/95 p-8 shadow-xl">
                        <div className="space-y-4">
                            <form onSubmit={handleSubmit(processRegistration)}>
                                <FormInputBig type="email" label='Enter your email' name='email' reg={register} placeholder="Email Address" formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                                <FormInputBig type="password" label='Enter password' name='password' reg={register} placeholder="Password" formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                                <FormInputBig type="password" label='Confirm Password' name='confirmPassword' reg={register} placeholder="Confirm Password" formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                                <FormInputBig type="text" label='Your Referrer ID' name='refSalt' reg={register} placeholder="Referrer ID" formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                                <LargePrimaryButton type="submit" disabled={!isValid || blockDiv || !appState.captchaVerified} isloading={blockDiv ? 1 : 0}>
                                    <span>Register Now</span>
                                    <ArrowRightCircle size={25} />
                                </LargePrimaryButton>
                                <p className='text-light text-xs mt-4 text-left'>By registering, you agree to Dexloot's <a href={appConfig.termsLink} target='blank' className='font-bold text-lighter'>Terms and conditions.</a></p>
                            </form>
                        </div>
                    </div>
                    <NeedHelp />
                </div>
            </div>
            <p className='text-gray-700 text-xs my-4 text-center'>Dexloot is protected by reCAPTCHA and the Google  <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        </main>
    );
};

export default Register;