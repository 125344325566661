import { yupResolver } from '@hookform/resolvers/yup';
import { default as React, useState } from 'react';
import { ArrowRightCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import NeedHelp from '../../Components/auth/NeedHelp';
import WelcomeTitle from "../../Components/auth/WelcomeTitle";
import { useAppState } from '../../Contexts/appStateContext';
import { useCallAPI } from '../../Hooks/callAPI';
import loginBg from "../../Theme/assets/img/loginbg.jpg";
import { LargePrimaryButton } from "../../Utility/Buttons";
import { FormInputBig } from '../../Utility/Utility';

const Reset = () => {
  
    const callAPI = useCallAPI();
    const history = useNavigate();
    const appState = useAppState();

    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    const FormSchema = yup.object().shape({
      password: yup.string().min(4).max(50).required('Password is required'),
      confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
      reset_code: yup.string().required('OTP is required').min(4).max(4),
    });

    const {
        getValues,
        register,
        handleSubmit,
        formState: { errors, isValid, isDirty }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const processForm = async () => {

        setBlockDiv(true);
        var formData = getValues();
        formData.email = localStorage.getItem('resetemail');
        try {
            const res = await callAPI("resetPassword", formData);
            if(res.data.state === 1) { 
              toast.success("You can login now");
              history('/login');
            } else if(res.data.state === 2) { //Success
              toast.error('You are banned. Please try after some time.');
              throw res.data.error;
            } else {
              res.data.formError && setFormError(res.data.formError);
              throw(res.data.error);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        } finally {
          setBlockDiv(false);
        }
        
    }

    return (
      <main className="mb-[60px] md:mb-[75px]">
          <div className="px-4 md:px-11 lg:px-0 max-w-[1024px] mx-auto">
              <div className="py-12 pt-7">
                  <WelcomeTitle
                      title="Reset Password?"
                      text="Please check your registered email for the OTP and enter it here with new password to proceed."
                  />
              </div>
              <div 
                  className="md:grid grid-cols-2 items-center gap-6 lg:gap-0 space-y-10 md:space-y-0 max-w-md overflow-hidden mx-auto md:mx-0 md:max-w-none relative rounded-[30px] shadow-xl bg-cover border-[#1f1f1f] border-[4px]"
                  style={{ backgroundImage: `url(${loginBg})`, backgroundPosition: 'top center' }}
              >
                  <div className="space-y-10 bg-darkest/95 p-8 shadow-xl">
                      <div className="space-y-4">
                          <form onSubmit={handleSubmit(processForm)}>
                                <FormInputBig type="text" label='Enter OTP sent to your email' name='reset_code' placeholder="1234" formerror={formError} errors={errors} reg={register} isdirty={isDirty ? 1 : 0} />
                                <FormInputBig type="password" label='Enter new password' name='password' reg={register} placeholder="Password" formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                                <FormInputBig type="password" label='Confirm Password' name='confirmPassword' reg={register} placeholder="Confirm Password" formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                                <LargePrimaryButton type="submit" disabled={!isValid || blockDiv || !appState.captchaVerified} isloading={blockDiv ? 1 : 0}>
                                  <span>Reset Password </span>
                                  <ArrowRightCircle size={25} />
                                </LargePrimaryButton>
                                <p className='text-light text-xs mt-4 text-left'>Remember Password? <Link to={'/login'} className='font-bold text-lighter'>login here</Link></p>
                          </form>
                      </div>
                  </div>
                  <NeedHelp />
              </div>
          </div>
      </main>
    );
};

export default Reset;
