import { setupIonicReact } from '@ionic/react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from './Contexts/ScrollToTop';
import { AppStateProvider } from './Contexts/appStateContext';
import { UserAuthProvider } from './Contexts/authContext';
import Layout from './Routers/Layout';

setupIonicReact();

function App() {
  return (
      <AppStateProvider>
        <UserAuthProvider>
          <Router>
            <ScrollToTop />
            <Layout />
          </Router>
          <Toaster position="top-center" toastOptions={{
            className: '',
            style: {
              border: '1px solid #333',
              background: '#333333',
              color: '#fff',
            },
          }}/>
        </UserAuthProvider>
      </AppStateProvider>
  );
}

export default App;
