import { Menu, Transition } from "@headlessui/react";
import { Chip, Tooltip } from "@material-tailwind/react";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";
import bnb from "../../../Theme/assets/img/binance.svg";
import logo from "../../../Theme/assets/img/logo-full-black.png";
import logoM from "../../../Theme/assets/img/logo-only-black.png";
import avater from "../../../Theme/assets/img/user.png";
import wallet from "../../../Theme/assets/img/wallet.svg";
import Avatar from "react-avatar";

const DashboardHeader = ({ onToggleSidebar }) => {

  const userAuth = useUserAuth();
  const helper = useHelpers();
  const isSmallScreen = useMediaQuery({ maxWidth: 1279 });

  return (
    <header className="bg-gradient-twelve rounded-tl-2xl rounded-tr-2xl">
      <div className="flex items-center justify-between h-[90px] px-3 md:px-5">
        <Link>
          {/* Mobile */}
          <img className="hidden sm:block h-[50px]" src={logo} alt="Logo" />
          {/* other */}
          <img className="block sm:hidden h-[33px]" src={logoM} alt="Logo" />
        </Link>

        <div className="flex items-center justify-end space-x-3 md:space-x-4">
          {/* Wallet markup start  */}
          <div className="flex items-center space-x-3 xl:border-r xl:border-[#627A90] xl:mr-[10px] xl:pr-6">
            <div className="text-end hidden xl:block">
              <span className="block text-white text-xs font-ibm-plex-sans font-normal">
                My Wallet Address
              </span>
              <strong className="block text-primary text-md leading-tight">
                <helper.trimAddress address={userAuth?.userBootstrap?.wallet?.address} copy />
              </strong>
            </div>
            <Menu as="div" className="relative">
              <div className="bg-[#1C2731] xl:bg-transparent rounded-10 xl:rounded-none w-[36px] sm:w-[47px] h-[36px] sm:h-[47px] xl:w-auto xl:h-auto flex items-center justify-center">
                <Menu.Button disabled={isSmallScreen ? false : true}>
                  <img
                    src={wallet}
                    alt="wallet"
                    className="w-6 md:w-7 xl:w-7"
                  />
                </Menu.Button>
              </div>
              {isSmallScreen && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    as="ul"
                    className="absolute top-full right-0 mt-1 w-48 origin-top-right z-30 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <Menu.Item>
                      <li className="px-4 py-2 space-x-1">
                        <span className="block text-black text-sm font-ibm-plex-sans font-normal">
                          My Wallet Address
                        </span>
                        <strong className="block text-black text-lg leading-tight">
                          <helper.trimAddress address={userAuth?.userBootstrap?.wallet?.address} copy />
                        </strong>
                      </li>
                    </Menu.Item>
                    <Menu.Item>
                      <li className="px-4 py-2 space-x-1">
                        <span>My Wallet Balance</span>
                        <Tooltip content="Your wallet's balance in USD and BNB">
                          <strong className="text-black text-base_next leading-tight flex items-center text-right">
                            <helper.AmountToCurrency amount={userAuth?.userBootstrap?.wallet?.balance_cache_usd} decimals={2} positive={true} />
                            <span className="text-primary px-[5px] py-[2px] shadow-md rounded-md flex items-center align-middle text-sm ml-1 bg-gray-900 space-x-1"><img src={bnb} className="w-[13px]" /><helper.AmountToCurrency amount={userAuth?.userBootstrap?.wallet?.balance_cache} prefix="" decimals={4}  positive={true}  /></span>
                          </strong>
                        </Tooltip>
                      </li>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              )}
            </Menu>
          </div>
          {/* Wallet markup end  */}
          {/* User markup start  */}
          <div className="flex items-center xl:space-x-3">
            <div className="text-end hidden xl:block">
              <span className="block text-white text-sm font-ibm-plex-sans font-normal">
                My Wallet Balance
              </span>
              <Tooltip content="Your wallet's balance in USD and BNB">
                <strong className="text-primary text-md leading-tight flex items-center text-right">
                  <helper.AmountToCurrency amount={userAuth?.userBootstrap?.wallet?.balance_cache_usd} decimals={2}  positive={true}  />
                  <Chip color="blue-gray" variant='gradient' size="sm" className="ml-[7px]" value={<span className="flex align-middle items-center"><img src={bnb} className="w-[13px]" /><helper.AmountToCurrency amount={userAuth?.userBootstrap?.wallet?.balance_cache} prefix="" decimals={4} /></span>}  positive={true}  />
                </strong>
              </Tooltip>
            </div>
            <Menu as="div" className="relative">
              <div className="flex items-center justify-center">
                <Menu.Button disabled={isSmallScreen ? false : true}>
                  <Avatar src={userAuth?.userBootstrap?.userData?.avatar} size="45" className="w-9 h-9 sm:w-[45px] sm:h-[45px] rounded-full block" />
                </Menu.Button>
              </div>
              {isSmallScreen && (
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    as="ul"
                    className="absolute top-full right-0 mt-1 w-48 origin-top-right z-30 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <Menu.Item>
                      <li className="px-4 py-2 space-x-1">
                        <span>Howdy</span>
                        <strong className="text-primary text-md">{userAuth?.userBootstrap?.userData?.username}</strong>
                      </li>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              )}
            </Menu>
            <Link to={'/settings'}>
              <div className="hidden xl:block">
                <span className="block text-white text-sm font-ibm-plex-sans font-normal">
                  Howdy
                </span>
                <strong className="block text-primary text-md leading-tight">
                  {userAuth?.userBootstrap?.userData?.username}
                </strong>
              </div>
            </Link>

          </div>
          {/* User markup end  */}

          <button
            className="xl:hidden"
            disabled={isSmallScreen ? false : true}
            onClick={() => onToggleSidebar()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#F1BE6B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-8 h-8"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
