import React, { Fragment } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";
import cashImg from "../../../Theme/assets/img/cash.png";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const CashBalance = () => {
  
  const auth = useUserAuth();
  const helper = useHelpers();

  return (
    <div className='bg-blue-800 shadow-lg rounded-xl py-2 px-3 sm:flex align-middle items-center sm:space-x-3 space-y-2 sm:space-y-0'>
      <div className="w-sm">
        <img src={cashImg} className="w-[45px]" />
      </div>
      <div className="">
        <div className="text-primary text-xl leading-[26px] font-bold font-ibm-plex-sans"><helper.AmountToCurrency amount={auth?.userBootstrap?.userData?.cash_balance} decimals={3} /></div>
        <div className="text-white text-xs leading-[15px] font-normal font-ibm-plex-sans">My Cash Balance</div>
      </div>
      <div className="">
        <Link to={'/transferCash'}><Button size="sm" variant='gradient'>Transfer</Button></Link>
      </div>
    </div>
  );
};

export default CashBalance;
