import React, { useState } from "react";
import DashboardFooter from "../Components/dash/layout/Footer";
import DashboardHeader from "../Components/dash/layout/Header";
import DashboardSidebar from "../Components/dash/layout/Sidebar";
import dashboardBg from "../Theme/assets/img/dashboard-bg.png";
import { BackDrop } from "../Utility/Utility";
import AppRouter from "./AppRouter";

export default function DashboardLayout() {  
  
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar((prevState) => !prevState);
  };
  
  return (
    <div className="bg-gradient-two p-3 md:p-5 min-h-screen">
      <BackDrop showSidebar={showSidebar} onClick={toggleSidebar} />
      <DashboardHeader onToggleSidebar={toggleSidebar} />
      <main className="xl:flex mb-6 rounded-bl-2xl rounded-br-2xl overflow-hidden relative">
        <div
          className="w-full h-full absolute top-0 start-0 bottom-0 end-0 grayscale-[50%] bg-cover bg-center rounded-bl-2xl rounded-br-2xl"
          style={{ backgroundImage: `url(${dashboardBg})` }}
        ></div>
        <DashboardSidebar showSidebar={showSidebar} onHideSidebar={toggleSidebar} />
        <div className="xl:w-[calc(100%_-_230px)] p-3 sm:p-5 2xl:p-6 relative z-[1]">
          <AppRouter />
        </div>
      </main>
      <DashboardFooter />
    </div>
  );
}