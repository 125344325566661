import { Dialog, Transition } from '@headlessui/react';
import { Tooltip } from '@material-tailwind/react';
import React, { Fragment } from 'react';
import { X } from 'react-feather';
import { useUserAuth } from '../../../Contexts/authContext';
import SurfAdIFrameBox from '../watchAds/IFrameBox';

const OfferwallIframe = ({ wall, isOpen, dismiss }) => {

    const auth = useUserAuth();

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" onClose={() => null} className="relative z-[100]">
                <div className="bg-white fixed inset-0 w-full h-full">
                    <Tooltip content="Close Offerwall">
                        <div onClick={() => { dismiss(); auth?.getAppBootstrap() }} className='bg-green-600 opacity-80 z-10 rounded-full p-2 absolute right-[30px] top-[30px] cursor-pointer'>
                            <X size="25" color='#ffffff' />
                        </div>
                    </Tooltip>
                    <SurfAdIFrameBox media={wall.endpoint} counterHook={false} />
                </div>
            </Dialog>
        </Transition>
    )
}

export default OfferwallIframe;