import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { default as React, useState } from 'react';
import { Clock, Percent, Zap } from 'react-feather';
import { toast } from 'react-hot-toast';
import Timestamp from 'react-timestamp';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from '../../../Hooks/callAPI';
import { useHelpers } from '../../../Hooks/helpers';
import { SmallPrimaryButton } from '../../../Utility/Buttons';
import { SVGLighting } from "../../../Utility/Utility";
import GoalBlock from './GoalBlock';

const GoalItemModal = ({ goal, handleClaimSuccess }) => {

    const helper = useHelpers();
    const auth = useUserAuth();
    const callAPI = useCallAPI();

    const [loading, setLoading] = useState(false);

    const claimRewards = async () => {
        setLoading(true);
        const res = await callAPI(`claimGoal`, { id: goal.id });
        if(res.status && res.data.state*1 === 1) {
            handleClaimSuccess();
            setLoading(false);
        } else {
            toast.error(res.data.error );
        }
    }

    return (
        <>
            {goal &&
                <div className='p-5 pt-0'>
                    <GoalBlock goal={goal} />

                    <div className='border-2 overflow-hidden rounded-10 border-yellow-300 max-w-[400px] mx-auto my-4 sm:mt-7 divide-y divide-slate-200'>
                        <div className='font-bold text-md p-3 bg-yellow-300'>Instructions</div>
                        {goal.instructions && goal?.instructions.split(",").map((instr, index) => {
                            return (
                                <p className='text-sm px-3 py-3' key={index}>{index+1}. {instr}</p>
                            );
                        })}      
                    </div>

                    <div className='sm:grid sm:grid-flow-col sm:max-w-3xl sm:mx-auto gap-10 items-center'>
                        {goal.offer > 0 && 
                            <div className='my-4 flex text-left items-center justify-left p-3 border-2 rounded-10 border-red-600'>
                                <Percent size={30} className='text-red-600 ml-2 mr-4' />
                                <div>
                                    <p className='font-semibold text-sm'>Earn <helper.renderNumber amount={goal.offer} decimals={0} prefix={"+"} suffix="%" /> more rewards for this goal</p>
                                    <p className='text-xsm leading-4'>Active for <Timestamp date={goal.offerExpire} options={{ format:"time", includeDay: true }} relativeTo={Date.now()/1000} /></p>
                                </div>
                            </div>      
                        }
                        {goal.frequency*1 > 0 && 
                            <div className='my-4 flex text-left items-center justify-left p-3 border-2 rounded-10 border-blue-600'>
                                <Clock size={30} className='text-blue-600 ml-2 mr-4' />
                                <div>
                                    <p className='font-semibold text-sm'>This is a repetitive goal</p>
                                    {goal.isGoalClaimable === 'yes' && <p className='text-xsm leading-4'>Claim rewards every {goal.frequency} hours</p>}
                                    {goal.isGoalClaimable === 'no' && <p className='text-xsm leading-4'>Claim rewards again in  <Timestamp date={goal.lastClaimed*1+(goal.frequency*3600)} options={{ format:"time", includeDay: true }} relativeTo={Date.now()/1000} /></p>}
                                </div>
                            </div>   
                        }
                        {goal.vipOnly === "yes" && auth?.userBootstrap?.userMembership?.id*1 === 1 && 
                            <div className='my-4 flex text-left items-center justify-left p-3 border-2 rounded-10 border-yellow-400'>
                                <Zap size={30} className='text-yellow-400 ml-2 mr-4' />
                                <div>
                                    <p className='font-semibold text-sm'>This is a PRO only goal</p>
                                    <p className='text-xsm leading-4'>Become a PRO user to claim</p>
                                </div>
                            </div>   
                        }
                    </div>

                    {goal.isGoalClaimable === 'yes' && 
                        <SmallPrimaryButton isloading={loading ? 1 : 0} disabled={loading || goal.progress < 100 || (goal.vipOnly === "yes" && auth?.userBootstrap?.userMembership?.id*1 === 1)} onClick={claimRewards}>Claim Rewards <SVGLighting width="20" height="20" stroke="#000" /></SmallPrimaryButton>
                    }
                    
                    {goal.lastClaimed > 0 && goal.isGoalClaimable === "no" &&
                        <div className='sm:max-w-3xl sm:mx-auto flex text-left items-center justify-left p-3 rounded-10 bg-green-500'>
                            <CheckCircleIcon className='text-gray-100 ml-2 mr-4 min-w-[35px] max-w-[35px]' />
                            <div className='text-gray-100'>
                                <p className='font-semibold text-sm'>Reward Claimed!</p>
                                <p className='text-xsm leading-4'>Congratulations! You have completed this goal and the reward has been credited to your balance.</p>
                            </div>
                        </div>
                    }

                </div>
            }
        </>
    )
}

export default GoalItemModal;