import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList, Tooltip } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { ArrowUpRight, Clock, DollarSign, Filter, Trash } from 'react-feather';
import Timestamp from 'react-timestamp';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallInfiniteResultsAPI } from '../../Hooks/callInfiniteResultsAPI';
import { useHelpers } from '../../Hooks/helpers';
import appConfig from '../../Utility/Config/config';
import { EmptyRecords, LoadingData, TitleBase } from '../../Utility/Utility';

const WalletActivity = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const infiniteResults = useCallInfiniteResultsAPI();

    const [options, setOptions] = useState({
        limit: 10,
        filterType: 'all',
        filterModule: 'all',
        filterAmountType: 'all'
    });

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            infiniteResults.getResults({ apiURL: 'getActivities/activities_wallet', reset: true, options: options });
        }
    }, [auth?.appBootstrapped]);

    //Onchange Handlers
    const handleOptionsChange =  async (key, value) => {
        setOptions({...options, [key]: value});
    }

    useEffect(() => {
        infiniteResults.getResults({ apiURL: 'getActivities/activities_wallet', reset: true, options: options });
    }, [options]);

    return (
        <div>
            <div className="mb-6 flex flex-wrap items-center justify-between space-y-4 sm:space-y-0">
                <div className="flex flex-wrap items-center">
                    <TitleBase>Wallet Activity</TitleBase>
                </div>
                <div className="flex items-center basis-full sm:basis-0 justify-end space-x-2">
                    <Menu>
                        <MenuHandler>
                            <Button size='sm' color="white">{options.filterModule}</Button>
                        </MenuHandler>
                        <MenuList className='font-ibm-plex-sans'>
                            <MenuItem className='flex justify-between' onClick={() => handleOptionsChange("filterModule", "all")}>Show All {options.filterModule == "all" && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                            <hr className="my-3" />
                            <MenuItem className='flex justify-between' onClick={() => handleOptionsChange("filterModule", "coinflipGame")}>Coinflip {options.filterModule == "coinflipGame" && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu>
                        <MenuHandler>
                            <IconButton size='sm' color="green"><DollarSign size={18} /></IconButton>
                        </MenuHandler>
                        <MenuList className='font-ibm-plex-sans'>
                            <MenuItem className='flex justify-between' onClick={() => handleOptionsChange("filterType", "direct")}>Direct {options.filterType == "direct" && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                            <MenuItem className='flex justify-between' onClick={() => handleOptionsChange("filterType", "commission")}>Commission {options.filterType == "commission" && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                            <hr className="my-3" />
                            <MenuItem className='flex justify-between text-red-400' onClick={() => handleOptionsChange("filterType", "all")}>Reset <Trash size={15} /></MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu>
                        <MenuHandler>
                            <IconButton size='sm' color="blue"><Filter size={18} /></IconButton>
                        </MenuHandler>
                        <MenuList className='font-ibm-plex-sans'>
                            <MenuItem className='flex justify-between' onClick={() => handleOptionsChange("filterAmountType", "credit")}>Credit {options.filterAmountType == "credit" && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                            <MenuItem className='flex justify-between' onClick={() => handleOptionsChange("filterAmountType", "debit")}>Debit {options.filterAmountType == "debit" && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                            <hr className="my-3" />
                            <MenuItem className='flex justify-between text-red-400' onClick={() => handleOptionsChange("filterAmountType", "all")}>Reset <Trash size={15} /></MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>

            <div className="bg-gradient-seven rounded-10 shadow-md px-4 py-3 mt-4 text-lighter font-ibm-plex-sans text-sm flex items-center gap-3">
                <InformationCircleIcon className="min-w-[30px] max-w-[30px]" />
                <div>BNB added into your account's wallet is not included in this activity. You can view your entire wallet history on the  <a href={appConfig.scanAddressLink + auth?.userBootstrap?.wallet?.address} target="blank" className='text-primary font-bold'>scan page</a></div>
            </div>
                
            <div className='mt-5'>
                {infiniteResults.data && infiniteResults.data.length > 0 && 
                    <>
                        {infiniteResults.data.map((data, k) => {
                            return (
                                <div key={k}>
                                    <p className='text-sm text-light font-bold my-4'><Timestamp date={data[0].created} options={{ format:"date", includeDay: true }} /></p>
                                    <div className="table-custom shadow-md">
                                        <table className="w-full font-ibm-plex-sans text-[#dbd8d8]">
                                            <thead>
                                                <tr className="shadow-sm">
                                                    <th>Created</th>
                                                    <th>Module</th>
                                                    <th>Amount</th>
                                                    <th className='text-left'>Description</th>
                                                    <th>Hash</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.map((e, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><helper.TimestampAndData created={e.created} /></td>
                                                        <td>{helper.capitalize(e.module)}</td>
                                                        <td className={`font-bold flex align-middle justify-center items-center space-x-1 ${e.amount_type == 'credit' ? '' : 'text-red-600'}`}>{e.amount_type === 'credit' ? <span>+</span> : <span>-</span>} <helper.AmountToCurrency amount={e.amount} decimals={3} /></td>
                                                        <td className='text-left' dangerouslySetInnerHTML={{__html: e.description}}></td>
                                                        <td className='flex justify-end'><helper.trimAddress address={e.transaction_id} scanURL={appConfig.scanTxnLink + e.transaction_id} copy /></td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        })}
                        {!infiniteResults.loadFull &&
                            <Button size='sm' color='blue' onClick={() =>  infiniteResults.getResults({ apiURL: 'getActivities/activities_wallet', reset: false, options: options })} loading={infiniteResults.loading} disabled={infiniteResults.loading}>Load More</Button>
                        }
                        {infiniteResults.loadFull &&
                            <Button size='sm' color='white' className='mt-2' disabled>All Rows Loaded</Button>
                        }
                    </>
                }
                
                {!infiniteResults.data &&
                    <LoadingData />
                }

                {infiniteResults.data && infiniteResults.data.length === 0 && 
                    <EmptyRecords title="No Activity Found" description="No activities found in this category" />
                }
            </div>     
        </div>
    )
}

export default WalletActivity;