import React, { useEffect, useState } from "react";
import { Avater, Button } from "../../../Utility/Utility";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import headsImg from "../../../Theme/assets/img/heads.png";
import tailsImg from "../../../Theme/assets/img/tails.png";
import { SmallPrimaryButton } from "../../../Utility/Buttons";
import { useUserAuth } from "../../../Contexts/authContext";
import { Button as MatButton } from '@material-tailwind/react';
import toast from "react-hot-toast";

const JoinedUserInfo = ({ contest, participant, allParticipants, type, joinContest, blockDiv }) => {

    const callAPI = useCallAPI();
    const helper = useHelpers();
    const auth = useUserAuth();

    const [data, setData] = useState({});

    useEffect(function() {
        getParticipantData();
    }, [participant]);

    const getParticipantData = async () => {
        if(!participant) {
            setData(false);
            return;
        }
        const res = await callAPI("getCoinflipParticipantDataByAddress/" + participant);
        if(res.status && res.data) {
            setData(res.data);
        }
    }

    return (
        <div className={`text-center ${contest.winnerDeclared && contest.winnerKey*1 !== type ? 'opacity-50' : ''}`}>
            <div className="relative w-[132px] mx-auto mb-8">
                <span className="absolute -left-16 top-2 text-white text-sm font-ibm-plex-sans font-bold pl-4 pr-16 py-2 bg-[#57565D] rounded-[5px]">
                    {type === 0 ? 'Caller' : 'Tosser'}
                </span>
                {type === 0 && contest.callerCalled*1 > 0 &&
                    <span className="absolute -right-3 -top-2 z-20">
                        {contest.callerCalled*1 === 1 ? 
                            <img src={headsImg} className="w-[50px]" />
                        :
                            <img src={tailsImg} className="w-[50px]" />
                        }
                    </span>
                }
                {type === 1 && allParticipants.length === 2 &&
                    <span className="absolute -right-3 -top-2 z-20">
                        {contest.callerCalled*1 === 2 ? 
                            <img src={headsImg} className="w-[50px]" />
                        :
                            <img src={tailsImg} className="w-[50px]" />
                        }
                    </span>
                }
                <Avater image={data.avatar} name={data.username} />
            </div>

            <div className="text-center">
                {data && <h5 className="text-white font-ibm-plex-sans font-bold text-xl mb-1">
                    {data.username}
                </h5>}
                {!data && <h5 className="text-gray-300 font-ibm-plex-sans font-bold text-xl mb-1">
                    Yet to decide
                </h5>}
                <strong className="mx-auto inline-block text-[#447A7E] text-sm_next font-ibm-plex-sans font-bold leading-tight mb-5">
                    {helper.trimAddress({ address: data.address, copy: true })}
                </strong>
                
                <div className="mx-auto max-w-[150px]">
                    {/* Define for type 0 - left side */}
                    {type === 0 &&
                        <>
                            {allParticipants.length === 0 && 
                                <SmallPrimaryButton isloading={blockDiv === 'joinBtn' ? 1 : 0} disabled={blockDiv === 'joinBtn' ? 1 : 0} onClick={() => joinContest(false)}>
                                    Call Now 
                                    <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-5"
                                    >
                                    <path
                                        d="M11.5439 13.9294H10.463C8.93991 13.9294 8.17835 13.9294 7.8888 13.434C7.59926 12.9386 7.97297 12.275 8.7204 10.9479L11.1082 6.70821C11.4832 6.04238 11.6707 5.70947 11.8573 5.7584C12.0439 5.80733 12.0439 6.18941 12.0439 6.95357V9.79562C12.0439 10.0313 12.0439 10.1492 12.1171 10.2224C12.1903 10.2956 12.3082 10.2956 12.5439 10.2956H13.6247C15.1478 10.2956 15.9094 10.2956 16.1989 10.791C16.4885 11.2864 16.1148 11.9499 15.3673 13.2771L12.9795 17.5168C12.6045 18.1826 12.417 18.5155 12.2304 18.4666C12.0439 18.4177 12.0439 18.0356 12.0439 17.2714V14.4294C12.0439 14.1937 12.0439 14.0758 11.9706 14.0026C11.8974 13.9294 11.7796 13.9294 11.5439 13.9294Z"
                                        fill="#33363F"
                                    />
                                    <ellipse
                                        cx="12.0437"
                                        cy="12.1125"
                                        rx="9.00469"
                                        ry="9.08437"
                                        stroke="#33363F"
                                        strokeWidth="2"
                                    />
                                    </svg>
                                </SmallPrimaryButton>
                            }
                            {contest.winnerDeclared && contest.winnerKey*1 === 0 && 
                                <Button className="flex items-center justify-center h-[44px] pl-5 pr-[18px] rounded-[7px] border-2 border-primary text-base font-ibm-plex-sans font-bold text-primary uppercase mx-auto min-w-[145px] text-center">
                                    Winner
                                </Button>
                            }
                        </>
                    }
                    
                    {/* Define for type 1 - right side */}
                    {type === 1 &&
                        <>
                            {contest.refunded ?
                                <MatButton size="sm" color="red" variant='outlined'>Contest Cancelled</MatButton>
                            :
                            <>
                                {(allParticipants.length === 1 && allParticipants[0] !== auth?.userBootstrap?.wallet?.address) && 
                                    <SmallPrimaryButton isloading={blockDiv === 'joinBtn' ? 1 : 0} disabled={blockDiv === 'joinBtn' ? 1 : 0} onClick={() => joinContest(contest.callerCalled*1 === 1 ? 2 : 1)}>
                                        Toss Now
                                        <svg
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="ml-5"
                                            >
                                            <path
                                                d="M11.5439 13.9294H10.463C8.93991 13.9294 8.17835 13.9294 7.8888 13.434C7.59926 12.9386 7.97297 12.275 8.7204 10.9479L11.1082 6.70821C11.4832 6.04238 11.6707 5.70947 11.8573 5.7584C12.0439 5.80733 12.0439 6.18941 12.0439 6.95357V9.79562C12.0439 10.0313 12.0439 10.1492 12.1171 10.2224C12.1903 10.2956 12.3082 10.2956 12.5439 10.2956H13.6247C15.1478 10.2956 15.9094 10.2956 16.1989 10.791C16.4885 11.2864 16.1148 11.9499 15.3673 13.2771L12.9795 17.5168C12.6045 18.1826 12.417 18.5155 12.2304 18.4666C12.0439 18.4177 12.0439 18.0356 12.0439 17.2714V14.4294C12.0439 14.1937 12.0439 14.0758 11.9706 14.0026C11.8974 13.9294 11.7796 13.9294 11.5439 13.9294Z"
                                                fill="#33363F"
                                            />
                                            <ellipse
                                                cx="12.0437"
                                                cy="12.1125"
                                                rx="9.00469"
                                                ry="9.08437"
                                                stroke="#33363F"
                                                strokeWidth="2"
                                            />
                                        </svg>
                                    </SmallPrimaryButton>
                                }
                                {(allParticipants.length === 1 && allParticipants[0] === auth?.userBootstrap?.wallet?.address) && 
                                    <MatButton onClick={() => toast.error('Please wait for an opponent to toss')} size="sm" color="white" variant='outlined' className="animate-pulse">Waiting Opponent</MatButton>
                                }
                                {contest.winnerDeclared && contest.winnerKey*1 === 1 && 
                                    <Button className="flex items-center justify-center h-[44px] pl-5 pr-[18px] rounded-[7px] border-2 border-primary text-base font-ibm-plex-sans font-bold text-primary uppercase mx-auto min-w-[145px] text-center">
                                        Winner
                                    </Button>
                                }
                            </>
                            }
                        </>
                    }
                </div>

            </div>
        </div>
    );
};

export default JoinedUserInfo;
