import NotificationSettings from "../../Components/dash/notifications/NotificationSettings";
import TelegramAlerts from "../../Components/dash/notifications/TelegramAlerts";
import { useUserAuth } from "../../Contexts/authContext";
import { FormBlock, TitleBase } from "../../Utility/Utility";

const Notifications = () => {

    const auth = useUserAuth();
    
    return (
        <>
            <div className="space-y-3 sm:flex justify-between items-center">
                <div className='space-y-3 '>
                    <TitleBase>Notifications Settings</TitleBase>
                </div>
            </div>
            <div className="">
                <div className='md:grid grid-cols-2 sm:gap-x-5'>
                    <div>
                        <FormBlock heading='NOTIFICATION SETTINGS'>
                            <NotificationSettings auth={auth} />
                        </FormBlock>
                    </div>
                    <div>
                        <FormBlock heading='TELEGRAM ALERT SYSTEM'>
                            <TelegramAlerts auth={auth}  />
                        </FormBlock>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Notifications;