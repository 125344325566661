import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { osName } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';
import { AlignLeft, ArrowDownCircle, ArrowUpCircle, Filter, Trash } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import GoalBlock from '../../Components/dash/goals/GoalBlock';
import GoalItemModal from '../../Components/dash/goals/GoalItemModal';
import CashBalance from '../../Components/dash/layout/CashBalance';
import { AppDialog } from '../../Components/modals/AppDialog';
import { AppModal } from '../../Components/modals/AppModal';
import { InfoModal } from '../../Components/modals/InfoModal';
import { useUserAuth } from '../../Contexts/authContext';
import { useAppDialog } from '../../Hooks/appDialog';
import { useAppModal } from '../../Hooks/appModal';
import { useCallAPI } from '../../Hooks/callAPI';
import { useInfoModal } from '../../Hooks/infoModal';
import { EmptyRecords, LoadingData, TitleBase } from '../../Utility/Utility';

const Goals = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const navigate = useNavigate();
    const [goals, setGoals] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState(false);
    const [allGoals, setAllGoals] = useState(false);
    const [filters, setFilters] = useState([]);
    const [currentSort, setCurrentSort] = useState(null);
    const goalParam = useParams();
    const goalModal = useAppModal();
    const appDialog = useAppDialog();
    const infoModal = useInfoModal();

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            getResults(true);
        }
    }, [auth?.appBootstrapped]);

    const getResults = async ( firstTime = false ) => {
        const deviceOS = osName.replace(/\s+/g, '').toLowerCase();
        const res = await callAPI("getGoals/" + deviceOS);
        if(res.status && res.data.goals && res.data.goals.length > 0) {
            setGoals(res.data.goals);
            setAllGoals(res.data.goals); //To get back to all after removing filter 
            setFilters([]);
            setCurrentSort(null);
            if(firstTime && goalParam.id) {
                handleGoalClick(res.data.goalsWithKey[goalParam.id]);
            }
        } else {
            setGoals([]);
            setAllGoals([]); //To get back to all after removing filter 
            setFilters([]);
            setCurrentSort(null);
        }
    }

    const filterGoalsByTag = async (filterTag) => {

        let newFilters = filters;
        if(filterTag !== "all") {
            //Check if filtertag is already preset or no
            if(newFilters.includes(filterTag)) {
                newFilters.splice(newFilters.indexOf(filterTag), 1);
            } else {
                newFilters.push(filterTag);
            }
        } else {
            newFilters.length = 0;
        }
        setFilters(newFilters);
        
        if(allGoals.length > 0 && newFilters.length > 0) {
            const filteredGoals = await allGoals.filter((goal) => {
                if(goal.tags === null ) {
                    return false;
                }
                let filterpassed = false;
                const goalTags = goal.tags.split(",");

                for (let i = 0; i < newFilters.length; i++) {
                    if(goalTags.includes(newFilters[i])) {
                        filterpassed = true;
                    } else {
                        filterpassed = false;
                        break;
                    }
                }

                if(filterpassed) {
                    return true;
                } else {
                    return false;
                }
                
            });            
            setGoals(filteredGoals);
        } else {
            setGoals(allGoals);
        }
    }

    const sortGoals = (type) => {
        setCurrentSort(type);
        switch (type) {
            case "amountAsc":
                setGoals((t) => [...t.sort((a, b) => {
                    return a.payout*1 - b.payout*1;
                })]);
                break;

            case "amountDesc":
                setGoals((t) => [...t.sort((a, b) => {
                    return b.payout*1 - a.payout*1;
                })]);
                break;

            case "offerDesc":
                setGoals((t) => [...t.sort((a, b) => {
                    return b.offerPercent*1 - a.offerPercent*1;
                })]);
                break;

            case "offerAsc":
                setGoals((t) => [...t.sort((a, b) => {
                    return a.offerPercent*1 - b.offerPercent*1;
                })]);
                break;
        
            default:
                break;
        }
    }

    //Declare modal
    const handleGoalClick = (goal) => {
        if(auth?.userBootstrap?.userData?.profile_completed === "no") {
            appDialog.open({
                header: 'Just one step away',
                description: 'Complete your profile now to start earning. Should take no more than a minute.',
                backdropDismiss: true,
                buttons: [
                    {
                        text: 'Complete Profile',
                        color: 'blue',
                        action: () => navigate("/settings")
                    }
                ]
            });
            return;
        }
        setSelectedGoal(goal);
        goalModal.open();
    }

    const handleClaimSuccess = async () => {
        infoModal.open({
            title: "Cash Claimed Successfully!", 
            description: 'Congratulations! Your claimed amount is credited in your cash balance.',
            icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
            showConfeti: true
        });
        getResults();
        goalModal.close();
        await auth.getAppBootstrap();
    }
    
    return (
        <>
            <div className="space-y-10">
                <div>
                    <div className="mb-6 flex justify-between items-center space-y-4 sm:space-y-0">
                        <TitleBase>Complete Goals & Earn</TitleBase>
                        <div className="flex items-center align-middle space-x-4">
                            {/* <Menu>
                                <MenuHandler>
                                    <IconButton color="blue"><Filter /></IconButton>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem className='flex justify-between' onClick={() => filterGoalsByTag("free")}>Free {filters.includes('free') && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                                    <MenuItem className='flex justify-between' onClick={() => filterGoalsByTag("vip")}>VIP {filters.includes('vip') && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                                    <MenuItem className='flex justify-between' onClick={() => filterGoalsByTag("adcredits")}>Ad Credit {filters.includes('adcredits') && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                                    <MenuItem className='flex justify-between' onClick={() => filterGoalsByTag("cash")}>Cash {filters.includes('cash') && <CheckCircleIcon className='w-[20px] text-gray-600' />}</MenuItem>
                                    <hr className="my-3" />
                                    <MenuItem className='flex justify-between text-red-400' onClick={() => filterGoalsByTag("all")}>Remove Filters <Trash size={15} /></MenuItem>
                                </MenuList>
                            </Menu> */}
                            <Menu>
                                <MenuHandler>
                                    <IconButton color='green'><AlignLeft /></IconButton>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem className={`flex justify-between ${currentSort === 'amountDesc' ?'text-blue-400':''}`} onClick={() => sortGoals("amountDesc")}>Amount High<ArrowUpCircle size={15} /></MenuItem>
                                    <MenuItem className={`flex justify-between ${currentSort === 'amountAsc' ?'text-red-400':''}`} onClick={() => sortGoals("amountAsc")}>Amount Low<ArrowDownCircle size={15} /></MenuItem>
                                    <MenuItem className={`flex justify-between ${currentSort === 'offerDesc' ?'text-blue-400':''}`} onClick={() => sortGoals("offerDesc")}>High Offer<ArrowUpCircle size={15} /></MenuItem>
                                    <MenuItem className={`flex justify-between ${currentSort === 'offerAsc' ?'text-red-400':''}`} onClick={() => sortGoals("offerAsc")}>Low Offer<ArrowDownCircle size={15} /></MenuItem>
                                </MenuList>
                            </Menu>
                            <CashBalance />
                        </div>
                    </div>
                    <div className="space-y-6">
                        {goals && goals.length > 0 && goals.map((goal) => {
                            return (
                                <div key={goal.id} onClick={() => handleGoalClick(goal)}>
                                    <GoalBlock goal={goal}  />
                                </div>
                            );
                        })}
                        {!goals &&
                            <LoadingData />
                        }
                        {goals && goals.length === 0 && 
                            <EmptyRecords title="No Goals Found" description="No goals available. Please check back later." />
                        }
                    </div>
                </div>
            </div>
            <AppModal Component={GoalItemModal} props={{ goal: selectedGoal, handleClaimSuccess: handleClaimSuccess } } hook={goalModal} />
            <AppDialog hook={appDialog} />
            <InfoModal hook={infoModal} />
        </>
    )
}
export default React.memo(Goals);