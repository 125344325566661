import { Button } from '@material-tailwind/react';
import { default as React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CashBalance from '../../Components/dash/layout/CashBalance';
import OfferwallBlock from '../../Components/dash/offerwalls/OfferwallBlock';
import OfferwallIframe from '../../Components/dash/offerwalls/OfferwallIframe';
import OfferwallItemModal from '../../Components/dash/offerwalls/OfferwallItemModal';
import StatisticsAds from "../../Components/dash/watchAds/StatisticsAds";
import { AppDialog } from '../../Components/modals/AppDialog';
import { AppModal } from '../../Components/modals/AppModal';
import { useUserAuth } from '../../Contexts/authContext';
import { useAppDialog } from '../../Hooks/appDialog';
import { useAppModal } from '../../Hooks/appModal';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import {
    EmptyRecords,
    LoadingData,
    TitleBase
} from "../../Utility/Utility";

const Offerwalls = () => {

    const navigate = useNavigate();
    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const appDialog = useAppDialog();
    const showWallInfo = useAppModal();

    const [offerwalls, setOfferwalls] = useState(false);
    const [stats, setStats] = useState(false);
    const [selectedWall, setSelectedWall] = useState(false);
    const [showWallIframe, setShowWallIframe] = useState(false);

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            getResults();
        }
    }, [auth?.appBootstrapped]);

    const getResults = async () => {

        const res = await callAPI("getOfferwalls");      
        if(res.status && res.data) {
            setOfferwalls(res.data.walls);
            setStats(res.data.stats);
        } else {
            setOfferwalls([]);
            setStats({});
        }
    }

    const openWall = async (wallKey) => {
        //Check if profile is complete, if not send to profile page
        if(auth?.userBootstrap?.userData?.profile_completed === "no") {
            appDialog.open({
                header: 'Just one step away',
                description: 'Complete your profile now to start earning. Should take no more than a minute.',
                backdropDismiss: true,
                buttons: [
                    {
                        text: 'Complete Profile',
                        color: 'blue',
                        action: () => navigate("/settings")
                    }
                ]
            });
            return;
        }
        setSelectedWall(offerwalls[wallKey]);
        showWallInfo.open();
    }

    return (
        <>
            <div className="">
                <div className="mb-6 flex justify-between items-center space-y-4 sm:space-y-0">
                    <div className=''>
                        <TitleBase>Offerwalls</TitleBase>
                        <div className='sm:flex mt-2 gap-2'>
                            <Button color='white' size='sm' variant='outlined' className='flex align-middle items-center mb-2 sm:mb-0'>1000 Points = &nbsp;<helper.AmountToCurrency amount={2} membershipMultiplier={"offerwall"} decimals={1} type='cash' iconSize={16} /></Button>
                            <Link to={'/offerwallsHistory'}><Button color='indigo' size='sm' variant='gradient'>Earnings History</Button></Link>
                        </div>
                    </div>
                    <div className="flex items-center align-middle space-x-4">
                        <CashBalance />
                    </div>
                </div>
                {stats &&
                    <div className="grid sm:grid-cols-2 gap-4 mb-6">
                        <StatisticsAds
                            total={stats.wallStatsToday.total}
                            price={helper.AmountToCurrency({ amount: stats.wallStatsToday.amount })}
                            text="Tasks Completed Today"
                            styles={`bg-gradient-eleven`}
                            />
                        <StatisticsAds
                            total={stats.wallStatsTotal.total}
                            price={helper.AmountToCurrency({ amount: stats.wallStatsTotal.amount })}
                            text="Total Tasks Completed"
                            styles={`bg-gradient-eleven`}
                            />
                    </div>
                }
                {offerwalls && offerwalls.length > 0 && 
                    <>
                        <div className="mb-6 flex flex-wrap">
                            <div className='text-white font-semibold text-md font-ibm-plex-sans '>Choose an Offerwall to start earning</div>
                        </div>
                        <div className='sm:grid sm:grid-cols-2 md:grid-cols-3 gap-5 space-y-5 sm:space-y-0'>
                            {offerwalls.map((e, k) => {
                                return (
                                    <div key={k} onClick={() => openWall(k)}>
                                        <OfferwallBlock wall={e} />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                }
                {!offerwalls &&
                    <LoadingData />
                }
                {offerwalls && offerwalls.length === 0 && 
                    <EmptyRecords title="No offerwalls Found" description="No offerwalls available. Please check back later." />
                }
            </div>
            <AppDialog hook={appDialog} />
            <AppModal Component={OfferwallItemModal} props={{ wall: selectedWall, setShowIframe: setShowWallIframe, dismiss: showWallInfo.close }} hook={showWallInfo} />
            {showWallIframe && <OfferwallIframe wall={selectedWall} isOpen={showWallIframe} dismiss={() => setShowWallIframe(false)} />}
        </>
    );
};
export default Offerwalls;