import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
} from "react";
import toast from "react-hot-toast";
import { useCallAPI } from "../Hooks/callAPI";

const UserAuth = createContext();

// context consumer hook
const useUserAuth = () => {
    // get the context
    const context = useContext(UserAuth);

    // if `undefined`, throw an error
    if (context === undefined) {
        throw new Error("UserAuth was used outside of its Provider");
    }

    return context;
};

const UserAuthProvider = ({ children }) => {

    //Declare state vars
    const [appBootstrapped, setAppBootstrapped] = useState(false);
    const [systemSettings, setSystemSettings] = useState({});
    const [userBootstrap, setUserBootstrap] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState('');
    const callAPI = useCallAPI();

    useEffect(function() {
        getAppBootstrap();
    }, []);

    const getAppBootstrap = async () => {
        const res = await callAPI("appBootstrap");
        console.log(res);
        if(res.status) {    
            setSystemSettings(res.data.appSettings);            
            if(res.data.loggedIn) { //Loggedin
                setLoggedIn(res.data.loggedIn);
                setUserId(res.data.userId);
                setUserBootstrap(res.data.userBootstrap);
            } else if(res.data.loggedIn === undefined) { //Handle error
                toast.error("Server error");
                console.log(res);
            }
            setAppBootstrapped(true);
        }
    }

    const notLoggedIn = () => {
        window.location.replace('/');
    }

    // memoize the full context value
    const contextValue = useMemo(() => ({
        appBootstrapped,
        systemSettings,
        userBootstrap,
        loggedIn,
        userId,
        getAppBootstrap,
        notLoggedIn
    }), [appBootstrapped, systemSettings, userBootstrap, loggedIn, userId, getAppBootstrap, notLoggedIn])

    return (
        <UserAuth.Provider value={ contextValue }>
            {children}
        </UserAuth.Provider>
    );
};

export { UserAuthProvider, useUserAuth };