import { useState } from 'react';
export const useSurferModal = () => {
    
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
        setIsOpen(true);
    }

    const close = () => {
        setIsOpen(false);
    }

    return { isOpen, open, close }
}