import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import countryList from 'react-select-country-list';
import * as Yup from 'yup';
import { useCallAPI } from '../../../Hooks/callAPI';
import { SmallPrimaryButton } from '../../../Utility/Buttons';
import { FormInput, FormSelect } from '../../../Utility/Utility';
import { useInfoModal } from '../../../Hooks/infoModal';
import { InfoModal } from '../../modals/InfoModal';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const ProfileSettings = ({ auth }) => {
    
    const callAPI = useCallAPI();
    const infoModal = useInfoModal();
    const navigate = useNavigate();

    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    const defaultValues = {
        username: auth?.userBootstrap?.userData?.username,
        gender: auth?.userBootstrap?.userData?.gender,
        country: auth?.userBootstrap?.userData?.country
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Please enter your Username')
            .min(5, "Please enter atleast 5 characters")
            .max(50, "Please keep it short to 50 characters"),
        country: auth?.userBootstrap?.userData?.country === "XX" ? Yup.string().required('Please select your Country').nullable(): Yup.string(),
        gender: Yup.string().required('Please choose your Gender').nullable(),
    });

    const {
        register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
    } = useForm({ defaultValues: defaultValues, mode: 'onChange', resolver: yupResolver(validationSchema) })

    const onSubmit = async () => {

        setBlockDiv(true);
        const formData = getValues();

        const res = await callAPI("updateUserProfile", formData);

        if(res.data.state === 1) { //Success
            toast.success("Details Updated");
            if(auth?.userBootstrap?.userData?.profile_completed === 'no') {
                infoModal.open({
                    title: "Profile Updated Successfully!", 
                    description: 'Lets begin your amazing journey at DexLoot.',
                    icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                    showConfeti: true,
                    onClick: () => navigate(-1),
                    onClickText: 'Begin Journey'
                });
            }
            auth?.getAppBootstrap();
            setBlockDiv(false);
        } else { //Handle error
            setBlockDiv(false);
            res.data.formError && setFormError(res.data.formError);
            toast.error(res.data.error);
            console.log(res.data);
        }

    }

    const countries = useMemo(() => countryList().getData(), []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className=''>
                    <FormInput name='username' label="Username" helper="A unique name for your account. Shown public." reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                </div>
                <div className="mt-4">
                    <FormSelect disabled={auth?.userBootstrap?.userData?.profile_completed === "yes"}  options={[
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female' }
                    ]} name="gender" label="Choose Gender" helper="Choose your gender" control={control} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                </div>
                <div className="mt-4">
                    <FormSelect disabled={auth?.userBootstrap?.userData?.profile_completed === "yes"}  options={[...countries]} name="country" label="Country" helper="Choose your country" control={control} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                </div>
                <div className='mt-4'>
                    <SmallPrimaryButton type="submit" disabled={!isValid || blockDiv} isloading={blockDiv ? 1 : 0}>Save Changes <ArrowRightCircleIcon width={23} /></SmallPrimaryButton>
                </div>
            </form>
            <InfoModal hook={infoModal} />
        </>

    );
};
export default ProfileSettings;