import { asUploadButton } from "@rpldy/upload-button";
import UploadPreview from "@rpldy/upload-preview";
import Uploady, { useBatchStartListener, useItemFinalizeListener } from "@rpldy/uploady";
import React, { forwardRef } from 'react';
import { UploadCloud } from "react-feather";
import { toast } from "react-hot-toast";
import { useDeviceData } from "../Hooks/deviceData";
import { useHelpers } from "../Hooks/helpers";
import appConfig from "./Config/config";
import { Button } from "@material-tailwind/react";

const UploadButton = asUploadButton(forwardRef(
    (props, ref) =>     
    <div className="flex items-center justify-end">
        <div className="w-[35px] mr-4"><UploadPreview  /></div>
        <div className="">
            <Button type="button" color="blue" size='sm' {...props} className="flex justify-between items-center">
                <p>choose file</p> <UploadCloud className="ml-4" size={18} />
            </Button>
        </div>
    </div>
));

const UploadFile = ({ type, fileSize, allowedTypes, setUploading, handleUploadSuccess, folder }) => {

    const helper = useHelpers();
    const device = useDeviceData();

    //VAlidate file upload 
    const checkFileUpload = (file, size) => {
        try {
            if(file.size > size) {
                throw new Error("File size cannot exceed " + size/1000000 + " MB");           
            }
            return true;     
        } catch (error) {
            toast.error(error.message);
            return false;
        }
    }

    //File uploader 
    const UploadStartedEvent = () => {

        useBatchStartListener((batch) => {
            setUploading(true);
        });

        useItemFinalizeListener((item) => {
            setUploading(false);
        });

        return null;
    }

    const uploadSuccess = (e, target) => {
        console.log(e);
        const op = JSON.parse(e);
        if(op.state === 1) { //Success
            handleUploadSuccess(type, op.url);
            toast.success("Media Uploaded. Save to continue.");
            setUploading(false);
        } else { //Handle error
            console.log(op);
            toast.error(op.error);
            setUploading(false);
        }
    }
    
    return (
        <Uploady 
            multiple={false} 
            clearPendingOnAdd={true}
            accept={allowedTypes}
            fileFilter={(e) => checkFileUpload(e, fileSize)}
            destination={{ 
                url: appConfig.apiEndpoint + "uploadMedia/" + type + "/" + folder, 
                filesParamName: type,
                headers: {
                    'deviceid': device.getDeviceId(),
                    'userauthtoken': device.getUserAuthToken()
                }
            }}
            formatServerResponse={(e) => uploadSuccess(e, type)}
        >
            <UploadStartedEvent />
            <UploadButton />
        </Uploady>
    );
};

export default UploadFile;