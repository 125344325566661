import { IconButton } from '@material-tailwind/react';
import React from 'react';
import { AlertTriangle, CheckCircle, Loader } from 'react-feather';
import Timestamp from 'react-timestamp';
import { useHelpers } from '../../../Hooks/helpers';
import { formatEther } from 'viem';
import appConfig from '../../../Utility/Config/config';

const WithdrawalsHistoryItem = ({ data }) => {

    const helpers = useHelpers();

    const renderStatus = [];
    renderStatus['pending'] = {
        icon: <Loader size={16} className='animate-spin' />,
        color: 'white'
    };
    renderStatus['processing'] = {
        icon: <Loader size={16} className='animate-spin' />,
        color: 'blue'
    };
    renderStatus['complete'] = {
        icon: <CheckCircle size={16} className=''/>,
        color: 'green'
    };
    renderStatus['failed'] = {
        icon: <AlertTriangle size={16} className=''/>,
        color: 'red'
    };

    return (
        <tr>
            <td className='font-bold'><helpers.AmountToCurrency amount={data.amount*1} /></td>
            <td className='flex justify-center'><helpers.TimestampAndData created={data.created} /></td>
            <td className='font-bold'><helpers.AmountToCurrency amount={formatEther(data.amount_bnb*1)} prefix='' />&nbsp; BNB</td>
            <td><helpers.trimAddress address={data.to_wallet} scanURL={appConfig.scanAddressLink + data.to_wallet} copy /></td>
            <td><helpers.trimAddress address={data.hash} scanURL={appConfig.scanTxnLink + data.hash} copy /></td>
            <td><helpers.capitalizeString text={data.status} /> <IconButton size='sm' className='ml-1' color={renderStatus[data.status].color}>{renderStatus[data.status].icon}</IconButton></td>
        </tr>
    )
}

export default React.memo(WithdrawalsHistoryItem);