const runDevAsProd = false;
const settings = {
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.0.1',
    appLink: 'https://app.dexloot.com/',
    apiEndpoint: settings.useDevServer?'https://dev.dexloot.com/app/':'https://api.dexloot.com/app/',

    //3rd party services
    gaCode: "G-T832VJC27Y",
    reCaptchaKey: '6LdKr1opAAAAAOOMS_g7lPKZqRdtcKr8y1BwDMqy',

    //Links for scan page hook
    scanAddressLink: 'https://bscscan.com/address/',
    scanTxnLink: 'https://bscscan.com/tx/',
    scanContractLink: 'https://bscscan.com/contract/',

    //External links
    termsLink: 'https://www.dexloot.com/terms-conditions',
    supportLink: 'https://dexloot.freshdesk.com/support/tickets/new'
}
export default appConfig;