import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '@material-tailwind/react';
import React from 'react';
import { X } from 'react-feather';
import { LargePrimaryButton } from '../../../Utility/Buttons';
import OfferwallBlock from './OfferwallBlock';
import appConfig from '../../../Utility/Config/config';

const OfferwallItemModal = ({ wall, setShowIframe, dismiss }) => {

    const showIframe = () => {
        setShowIframe(true);
        dismiss();
    }
    return (
        <>
            {wall && 
                <div className='p-5 pt-0 md:grid grid-cols-[400px_auto] sm:space-x-5'>
                    <div className='mb-3'>
                        <OfferwallBlock wall={wall} />
                        <div className='mt-3'>
                            <LargePrimaryButton autoFocus onClick={showIframe}>Open Offerwall<ArrowRightCircleIcon width={28} /></LargePrimaryButton>
                        </div>
                        <p className='text-dark text-xs mt-4 text-left'>By using this offerwall, you agree to Dexloot's <a href={appConfig.termsLink} target='blank' className='font-bold text-dark'>Terms and Conditions</a></p>
                    </div>
                    <div>
                        <div className='border-2 overflow-hidden rounded-10 border-gray-300 mx-auto mb-3 divide-y divide-slate-200'>
                            <div className='font-bold text-md p-3 bg-gray-300'>Instructions</div>
                                <p className='text-sm px-3 py-3'>{wall.name} offers variety of offers, surveys, downloads and games. Read the steps carefully before getting started.</p>
                                <p className='text-sm px-3 py-3'><b className='block'>Open Offerwall:</b> Open the offerwall by clicking the link below.</p>
                                <p className='text-sm px-3 py-3'><b className='block'>Choose an offer:</b> Browse through the offers available on the offerwall and select the one that interests you. Offers may include surveys, quizzes, games, downloads, or sign-ups for services.</p>
                                <p className='text-sm px-3 py-3'><b className='block'>Complete the offer:</b> Follow the instructions provided with the offer to complete it. For example, if you are taking a survey, answer the questions truthfully and thoroughly. If you are downloading an app, make sure to install and open it.</p>
                                <p className='text-sm px-3 py-3'><b className='block'>Claim your reward:</b> After completing the offer, your rewaerd will be credited to your DexLoot earnings balance.</p>
                                <p className='text-sm px-3 py-3'><b className='block'>Repeat:</b> You can continue to browse and complete offers on the offerwall to earn more rewards. However, be aware that some offers may require you to wait a certain amount of time or complete additional requirements before receiving your reward.</p>
                        </div>
                        <Button variant='outlined' color='red' onClick={dismiss} className='flex justify-between items-center w-full'>Close Offerwall<X size={25} /></Button>
                    </div>
                </div>
            }
        </>
    )
}

export default OfferwallItemModal;