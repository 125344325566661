import { Button } from '@material-tailwind/react';
import React, { useEffect } from 'react';
import Timestamp from 'react-timestamp';
import OfferwallHistoryItem from '../../Components/dash/offerwalls/OfferwallHistoryItem';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallInfiniteResultsAPI } from '../../Hooks/callInfiniteResultsAPI';
import { EmptyRecords, LoadingData, TitleBase } from '../../Utility/Utility';

const OfferwallsHistory = () => {

    const auth = useUserAuth();
    const infiniteResults = useCallInfiniteResultsAPI();

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            infiniteResults.getResults({ apiURL: 'getUserCompletedOffers', reset: true });

            let interval = setInterval(() => {
                infiniteResults.getResults({ apiURL: 'getUserCompletedOffers', reset: true });
            }, 10000);
            return () => {
                clearInterval(interval);
            };

        }
    }, [auth?.appBootstrapped]);

    return (
        <div className=''>
            <div className="mb-5">
                <TitleBase>Offerwalls History</TitleBase>
            </div>
            {infiniteResults.data && infiniteResults.data.length > 0 && 
                <>
                    {infiniteResults.data.map((data, k) => {
                        return (
                            <div key={k}>
                                <p className='text-sm text-light font-bold my-4'><Timestamp date={data[0].created} options={{ format:"date", includeDay: true }} /></p>
                                <div className="table-custom shadow-md">
                                    <table className="w-full font-ibm-plex-sans text-[#dbd8d8]">
                                        <thead>
                                            <tr className="shadow-sm">
                                                <th>Offerwall</th>
                                                <th>Task Name</th>
                                                <th>Points Earned</th>
                                                <th>Cash Earned</th>
                                                <th>Transaction ID</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.map((e, i) => {
                                            return (
                                                <OfferwallHistoryItem data={e} key={i} />
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    })}
                    {!infiniteResults.loadFull &&
                        <Button size='sm' color='blue' onClick={() =>  infiniteResults.getResults({ apiURL: 'getUserCompletedOffers', reset: false })} loading={infiniteResults.loading} disabled={infiniteResults.loading}>Load More</Button>
                    }
                    {infiniteResults.loadFull &&
                        <Button size='sm' color='white' className='mt-2' disabled>All Rows Loaded</Button>
                    }
                </>
            }
            
            {!infiniteResults.data &&
                <LoadingData />
            }

            {infiniteResults.data && infiniteResults.data.length === 0 && 
                <EmptyRecords title="No Tasks Completed" description="Your completed offerwall tasks will show here" />
            }
        </div>       
    )
}

export default OfferwallsHistory;