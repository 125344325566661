import { osName } from 'mobile-device-detect';
import { default as React, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AdBlockFull from '../../Components/dash/watchAds/AdBlockFull';
import AdBlockSmall from '../../Components/dash/watchAds/AdBlockSmall';
import StatisticsAds from "../../Components/dash/watchAds/StatisticsAds";
import { SurferModal } from '../../Components/dash/watchAds/SurferModal';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { useSurferModal } from '../../Hooks/watchAds/SurferModal';
import {
    AddValue,
    EmptyRecords,
    LoadingData,
    TitleBase
} from "../../Utility/Utility";
import CashBalance from '../../Components/dash/layout/CashBalance';
import { useAppDialog } from '../../Hooks/appDialog';
import { AppDialog } from '../../Components/modals/AppDialog';

const WatchAds = () => {

    const navigate = useNavigate();
    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const surferModal = useSurferModal();
    const appDialog = useAppDialog();

    const [ads, setAds] = useState(false);
    const [ad, setAd] = useState(false);
    const [adTypes, setAdTypes] = useState(false);
    const [stats, setStats] = useState(false);
    const [graph, setGraph] = useState(false);

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            getResults();
        }
    }, [auth?.appBootstrapped]);

    const getResults = async () => {

        const deviceOS = osName.replace(/\s+/g, '').toLowerCase();
        const res = await callAPI("getAdsForUser/" + deviceOS);      
        if(res.status) {
            if(res.data.ads) {
                const ads = helper.groupObjArrayByKey(res.data.ads, 'cpv_name');
                const types = Object.keys(ads);
                setAds(ads);
                setAdTypes(types);
            } else {
                setAds({});
                setAdTypes([]);
            }
            setStats(res.data.stats);
            setGraph(res.data.graphData);
        } else {
            setAds({});
            setStats({});
        }
    }
    
    const skipAd = async () => {
        const res = await api.call("skipSurfAd", true, { adId: ad.id });
        if(res.status) {
            getResults();
        }
    }

    const startSurfing = async (adId) => {
        
        //Check if profile is complete, if not send to profile page
        if(auth?.userBootstrap?.userData?.profile_completed === "no") {
            appDialog.open({
                header: 'Just one step away',
                description: 'Complete your profile now to start earning. Should take no more than a minute.',
                backdropDismiss: true,
                buttons: [
                    {
                        text: 'Complete Profile',
                        color: 'blue',
                        action: () => navigate("/settings")
                    }
                ]
            });
            return;
        }
        
        //Validate ad from server and send it to the surfer
        const res = await callAPI("surferGetAd/", { adId: adId });
        if(res.status && res.data.state*1 === 1) {
            setAd(res.data.ad);
            surferModal.open();
        } else {
            toast.error(res.data.error);
        }
    }

    const getAdResetTimestamp = () => {
        const now = new Date().getTime();
        let startOfDay = now - (now % 86400000);
        let endDate = startOfDay + 86400000;
        return endDate;
    }

    return (
        <>
            <div className="space-y-10">
                <div>
                    <div className="mb-6 flex justify-between items-center">
                        <TitleBase>Watch Ads</TitleBase>
                        <CashBalance />
                    </div>
                    {stats &&
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-5 2xl:gap-6">
                            <StatisticsAds
                                total={stats.adsAvailable.length}
                                price={helper.AmountToCurrency({ amount: stats.adsAvailable.reduce((pSum, num) => pSum + num.amount*1, 0), membershipMultiplier: "ad" })}
                                text="Available ads to watch"
                                styles={`bg-[#243D31]`}
                            />
                            <StatisticsAds
                                total={stats.surfedToday.total}
                                price={helper.AmountToCurrency({ amount: stats.surfedToday.amount, membershipMultiplier: "ad" })}
                                text="Ads watched today"
                                styles={`bg-[#343143] hover:bg-[#243D31]`}
                            />
                            <StatisticsAds
                                total={stats.surfed.total}
                                price={helper.AmountToCurrency({ amount: stats.surfed.amount, membershipMultiplier: "ad" })}
                                text="Total ads watched"
                                styles={`bg-[#343143] hover:bg-[#243D31]`}
                            />
                            <StatisticsAds
                                highlight={true}
                                total={auth?.userBootstrap?.userMembership?.surfAds_perDay}
                                text="Ads you can watch today"
                                styles={`bg-[#343143] hover:bg-[#243D31]`}
                            />
                        </div>
                    }
                </div>
                {adTypes && adTypes.length > 0 && 
                    adTypes.map((e, k) => {
                        return (
                            <div key={k}>
                                <div className="mb-6 flex flex-wrap items-center">
                                    <TitleBase>{e} Category</TitleBase>
                                    <span className="w-[1px] h-6 bg-[#475A55] inline-block mx-4"></span>
                                    <AddValue value={helper.AmountToCurrency({ amount: ads[e][0].cpv, membershipMultiplier: 'ad', decimals: 4 })} />
                                </div>
                                <div className="space-y-7 sm:space-y-0 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-5 2xl:gap-6">
                                    {ads[e].map((ad, key) => {
                                        return(
                                            <div key={key} onClick={() => startSurfing(ad.id)}>
                                                {e !== 'Silver' && <AdBlockFull ad={ad} />}
                                                {e === 'Silver' && <AdBlockSmall ad={ad} />}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })
                }
                {!adTypes &&
                    <LoadingData />
                }
                {adTypes && adTypes.length === 0 && 
                    <EmptyRecords title="No ads Found" description="No ads available. Please check back later." />
                }
            </div>
            {ad && <SurferModal key={ad.adKey} ad={ad} surferHook={surferModal} refreshCallback={getResults} creditAdFn={"surferCreditAd"} />}
            <AppDialog hook={appDialog} />
        </>
    );
};

export default WatchAds;