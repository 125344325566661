import headsImg from "../../../Theme/assets/img/heads.png";
import tailsImg from "../../../Theme/assets/img/tails.png";

export const ChooseCallModal = ({ joinContest, dismiss }) => {
    return (
        <div className="py-2">
            <div className="mt-4 text-center text-lg font-bold">Choose heads or tails</div>

            <div className="sm:max-w-[400px] md:max-w-[450px] grid grid-cols-[1fr_1fr] gap-10 mx-auto mb-8 mt-10 px-10">
                <div className="text-center cursor-pointer" onClick={ () => { joinContest(1); dismiss()} }>
                    <img src={headsImg} className="mx-auto sm:w-[100px] md:w-[150px]" />
                </div>
                <div className="text-center cursor-pointer" onClick={ () => { joinContest(2); dismiss()} }>
                    <img src={tailsImg} className="mx-auto sm:w-[100px] md:w-[150px]" />
                </div>
            </div>
        </div>
    )
}