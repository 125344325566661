import { useState } from 'react';

export const usePendingModal = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);

    const open = (newData) => {
        setIsOpen(true);
        setData(newData);
    }

    const close = () => {
        setIsOpen(false);
        setData(null);
    }

    return { isOpen, data, open, close };
}