import { Chip } from "@material-tailwind/react";
import { PuffLoader } from "react-spinners";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useHelpers } from "../../Hooks/helpers";

export const PendingModal = ({ hook }) => {
    const helpers = useHelpers();
    return (
        <BottomSheet open={hook.isOpen}>
            <div className='items-center text-center py-10 px-10'>
                <PuffLoader
                    color='#a9ba0d'
                    loading={true}
                    size={60}
                    aria-label="Loading"
                    data-testid="loader"
                    className='mb-5 mx-auto'
                />
                <h1 className="text-2xl sm:text-3xl text-dark mb-3 font-viga font-normal">Processing. Please wait...</h1>
                <p className="text-base sm:text-lg text-[#777] max-w-[390px] mx-auto font-ibm-plex-sans font-normal">
                    Blockchain Txn Hash : <Chip className='inline-block' size='sm' color='amber' value={<helpers.trimAddress address={hook.data} firstShow={5} secondShow={7} copy />} />
                    Transaction will be processed in 1-2 mins
                </p>
            </div>
        </BottomSheet>
    )
}
