import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Alert, Button, ButtonGroup, IconButton, Tooltip } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../Utility/Utility';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from '../../../Hooks/callAPI';
import toast from 'react-hot-toast';
import { useHelpers } from '../../../Hooks/helpers';
import { Copy, Info, RefreshCw, RotateCcw, X } from 'react-feather';
import CopyToClipboard from 'react-copy-to-clipboard';

const TelegramAlerts = ({ auth }) => {
    
    const callAPI = useCallAPI();
    const helper = useHelpers();

    const [tgData, setTgData] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            getResults();
        }
    }, [auth?.appBootstrapped]);

    const getResults = async () => {
        const res = await callAPI("telegramUserData");     
        if(res.status && res.data) {
            setTgData(res.data);
        }
    }

    const regenKey = async () => {
        setLoading(true);
        const res = await callAPI("telegramRegenerateSecretKey");
        if(res.data.state === 1) { //Success
            toast.success('Key Generated Successfully');
            getResults();
        } else { //Handle error
            toast.error(res.data.error);
            console.log(res.data);
        }
        setLoading(false);
    }

    const disconnect = async () => {
        setLoading(true);
        const res = await callAPI("telegramDeactivateAccount");
        if(res.data.state === 1) { //Success
            toast.success('Account disconnected from your Telegram');
            getResults();
        } else { //Handle error
            toast.error(res.data.error);
            console.log(res.data);
        }
        setLoading(false);
    }

    return (
        <div>
            <div className='bg-indigo-600 text-white text-sm px-4 py-3 rounded-lg mb-4 flex gap-2 align-middle items-center leading-snug'>
                <Info size={20} />
                Connect your telegram account to get alerts sent to your telegram app 
            </div>
            <div className="space-y-5">
                <div className="sm:flex justify-between gap-5 bg-gradient-three shadow-lg px-5 py-4 rounded-10">
                    <div>
                        <div className="font-bold text-md uppercase text-black">Step 1 - Open Telegram Alerts Bot</div>
                        <div className="text-sm text-gray-900">Click button to the right or simply copy the bot username and enter in your telegram search box. <b><helper.copyValue value={auth?.systemSettings?.TELEGRAM_BOT_ACCOUNT} /></b></div>
                    </div>
                    <div className="flex items-center justify-center mt-4 sm:mt-0">
                        <a href={'https://t.me/' + auth?.systemSettings?.TELEGRAM_BOT_ACCOUNT} target="blank"><Button size="sm" color="indigo">Open Bot</Button></a>
                    </div>
                </div>
                <div className="sm:flex justify-between gap-5 bg-gradient-three shadow-lg px-5 py-4 rounded-10">
                    <div>
                        <div className="font-bold text-md uppercase text-black">Step 2 - Enter Connection Code</div>
                        <div className="text-sm text-gray-900">Enter the connection string into the bot to instantly connect your account with the bot.</div>
                    </div>
                    <div className="flex items-center justify-center mt-4 sm:mt-0">
                        {tgData && tgData.secret_key && 
                            <div className='flex gap-2'>
                                <CopyToClipboard onCopy={() => toast.success('Copied!')} text={"/connect_" + tgData.secret_key}><Button className='flex justify-between gap-x-2' color='green' size='sm'>/connect_{tgData.secret_key} <Copy size={15} /></Button></CopyToClipboard>
                                <Tooltip content='Generate new key if your account is synced somewhere else'><IconButton size='sm' color='black'><RotateCcw size={15} /></IconButton></Tooltip>
                            </div>
                        }
                        {(!tgData || !tgData.secret_key) && <Button size="sm" color="black" onClick={regenKey} >Show Connect String</Button>}
                    </div>
                </div>
                <div className="sm:flex justify-between gap-5 bg-gradient-seven shadow-lg px-5 py-4 rounded-10">
                    <div>
                        <div className="font-bold text-md uppercase text-primary">Step 3 - Done</div>
                        <div className="text-sm text-gray-300">Once you have followed the steps correctly, your bot will be activated and you will start receiving notifications.</div>
                    </div>
                    <div className="flex items-center justify-center mt-4 sm:mt-0">
                        {tgData && tgData.client_username && 
                            <ButtonGroup color="indigo" size="sm">
                                <Button>Connected to {tgData.client_username}</Button>
                                <Button color="red" onClick={disconnect}><X size={15} /></Button>
                            </ButtonGroup>
                        }
                        {(!tgData || !tgData.client_username) && <Button onClick={getResults} size="sm" variant="outlined">Check Status</Button>}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TelegramAlerts;