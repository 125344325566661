import { useEffect, useState } from 'react';

export const useSurfAdDetail = () => {

    const [adCreditedRow, setAdCreditedRow] = useState(null);
    const [conversionRecorded, setConversionRecorded] = useState(false);
    const [userInControl, setUserInControl] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    //Show popup based initial
    useEffect(() => {
        if(userInControl === false && isOpen === false) {
            setTimeout(() => {
                setIsOpen(true);
            }, 1500);
            setTimeout(() => {
                setIsOpen(false);
            }, 6500);
        }
    }, []);

    const open = () => {
        setIsOpen(true);
    }

    const close = () => {
        setIsOpen(false);
    }
    return { 
        adCreditedRow, 
        conversionRecorded, 
        userInControl, 
        isOpen, 
        open, 
        close, 
        setIsOpen, 
        setConversionRecorded, 
        setUserInControl, 
        setAdCreditedRow
    };
}