import React from "react";
import { AdTimeCredit, AddWatchSchedule } from "../../../Utility/Utility";
import { useHelpers } from "../../../Hooks/helpers";

const AdBlockFull = ({ ad }) => {

  const helper = useHelpers();

  return (
    <div>
      <div className="bg-gradient-seven rounded-10 px-5 py-4 shadow-[0_4px_4px_0px_rgb(0,0,0,0.15)] ">
        <h5 className="text-md font-ibm-plex-sans text-gray-200 leading-tight mb-4 font-semibold">
          {ad.title}
        </h5>
        <div className="grid grid-cols-[60px_auto] gap-4 items-center">
          <img src={ad.thumbnail} alt="add" className="w-full max-w-[60px] max-h-[60px]" />
          <div className="space-y-3">
            <p className="text-sm font-ibm-plex-sans text-gray-200 leading-snug">
              {ad.description}
            </p>
            <AdTimeCredit time={ad.timer} credit={helper.AmountToCurrency({ amount: ad.cpv, membershipMultiplier: 'ad', decimals: 4 })} />
          </div>
        </div>
      </div>
      <div className="relative pb-[10px]">
        <div className="absolute top-0  left-5">
          <AddWatchSchedule text={"Watch every "+ ad.frequency + " hours"} />
        </div>
      </div>
    </div>
  );
};

export default AdBlockFull;
