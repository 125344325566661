import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@material-tailwind/react";
import React from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useHelpers } from "../../../Hooks/helpers";
import { Button, SVGClock, SVGLighting } from "../../../Utility/Utility";
import cashImg from "../../../Theme/assets/img/cash.png";

const GoalBlock = ({ goal }) => {

  const auth = useUserAuth();
  const helpers = useHelpers();

  const renderAmount = () => {
      if(goal.goalCompleted === "yes") {
          return goal.claimedPayout;
      }
      
      let finalAmount = goal.payout*auth?.userBootstrap?.userMembership?.goal_earnings/100;
      
      if(goal.offer > 0) {
          return finalAmount*goal.offer/100;
      }

      return finalAmount;
  }

  return (
    <div
      className={`${goal?.vipOnly === 'no' ? 'bg-gradient-ten' : 'bg-gradient-seven'} rounded-10 p-4 md:p-6 shadow-[0_4px_4px_0px_rgb(0,0,0,0.15)] sm:grid grid-cols-[70px_auto_auto] space-y-4 sm:space-y-0 gap-5 items-center cursor-pointer border-b-[6px] border-gray-900 hover:border-primary duration-500 ${goal?.lastClaimed > 0 && goal?.isGoalClaimable === "no" ? 'opacity-40' : ''}`}
    >
      <div className="text-center space-y-2">
        {goal?.lastClaimed > 0 && goal?.isGoalClaimable === "no" && 
          <CheckCircleIcon width={44} color="#90a985" className="mx-auto" />
        }
        {goal?.progress*1 === 100 && goal?.isGoalClaimable === "yes" && 
          <>
            <div className="text-primary text-base font-ibm-plex-sans font-bold animate-bounce">
              Claim
            </div>
            <div className="bg-[#A98585] rounded-2xl w-full h-[9px] overflow-hidden">
              <span className="bg-[#F8C571] w-12/12 h-full block"></span>
            </div>
          </>
        }
        {goal?.progress*1 < 100 && goal?.isGoalClaimable === "yes" && 
          <>
            <div className={`text-white text-base font-ibm-plex-sans font-bold ${goal.progress*1 > 1 ? '' : 'opacity-40'}`}>
              {goal.progress.toFixed(0)}%
            </div>
            <div className="bg-[#A98585] rounded-2xl w-full h-[9px] overflow-hidden">
              <span className={`bg-[#F8C571] h-full block`} style={{ width: goal.progress + '%' }}></span>
            </div>
          </>
        }
      </div>
      <div>
        <h5 className="text-md font-ibm-plex-sans text-white leading-tight mb-1 font-bold">
          {goal.name}
        </h5>
        <p className="text-sm font-ibm-plex-sans text-white leading-snug 2xl:leading-normal">
          {goal.description}
        </p>
      </div>
      <div className="flex items-center justify-end space-x-3">

        {goal.vipOnly === "yes" &&
          <Tooltip content="For PRO members only">
            <button className="w-[35px] h-[39px] flex items-center justify-center rounded-[5px] bg-[#B6D274]">
              <SVGLighting width="24" height="24" stroke="#33363F" />
            </button>
          </Tooltip>
        }
        {goal.frequency*1 > 0 && 
          <Tooltip content={'Repetitive goal. Earn rewards every ' + goal.frequency + ' hours'}>
            <button className="w-[35px] h-[39px] flex items-center justify-center rounded-[5px] bg-[#9E92FF]">
              <SVGClock width="24" height="24" stroke="#33363F" />
            </button>
          </Tooltip>
        }
        {goal.offer > 0 && 
          <Tooltip content={'Earn ' + goal.offer + '% more rewards for this goal'}>
            <button className="px-2 h-[39px] flex items-center justify-center rounded-[5px] bg-[#e97d7d] font-bold text-xsm">
              <helpers.renderNumber amount={goal.offer} decimals={0} prefix="+" suffix={"%"} />
            </button>
          </Tooltip>
        }

        <Button
          type="button"
          className="px-5 bg-[#363549] hover:bg-[#392B2B] transition duration-200 rounded-[5px] h-[39px] text-white font-ibm-plex-sans font-bold text-[15px]"
        >
          <helpers.AmountToCurrency amount={renderAmount()} type={goal.payout_type} iconSize={20} />
        </Button>
      </div>
    </div>
  );
};

export default GoalBlock;
