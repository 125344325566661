import { CheckBadgeIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { Button } from '@material-tailwind/react';
import { default as React, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from "react-router-dom";
import Timestamp from 'react-timestamp';
import { ChooseCallModal } from '../../Components/dash/coinflipGame/ChooseCallModal';
import CoinflipGameHistory from '../../Components/dash/coinflipGame/CoinflipGameHistory';
import GamewidgetCarousel from "../../Components/dash/coinflipGame/GameWidget";
import JoinedUserInfo from '../../Components/dash/coinflipGame/JoinedUserInfo';
import { AppDialog } from '../../Components/modals/AppDialog';
import { AppModal } from '../../Components/modals/AppModal';
import { InfoModal } from '../../Components/modals/InfoModal';
import { PendingModal } from '../../Components/modals/PendingModal';
import { useUserAuth } from '../../Contexts/authContext';
import { useAppDialog } from '../../Hooks/appDialog';
import { useAppModal } from '../../Hooks/appModal';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { useInfoModal } from '../../Hooks/infoModal';
import { usePendingModal } from '../../Hooks/pendingModal';
import { usePollAPI } from '../../Hooks/pollAPI';
import incomeImg from '../../Theme/assets/img/income.png';
import fairImg from '../../Theme/assets/img/judgement.png';
import refundImg from '../../Theme/assets/img/refund.png';
import vs from "../../Theme/assets/img/vs.svg";
import appConfig from '../../Utility/Config/config';
import { LoadingData, TitleBase, TitleSub } from "../../Utility/Utility";

const CoinflipGame = () => {

    const auth = useUserAuth();
    const helpers = useHelpers();
    const contestParam = useParams();
    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
    const pendingModal = usePendingModal();
    const chooseCallModal = useAppModal();
    const infoModal = useInfoModal();
    const appDialog = useAppDialog();

    const [contestId, setContestId] = useState(contestParam.id ? contestParam.id : false);
    const [contestData, setContestData] = useState(false);
    const [contestParticipants, setContestParticipants] = useState(false);
    
    const [blockDiv, setBlockDiv] = useState('');

    //Get info about contest from blockchain
    useEffect(() => {
        if(contestParam.id*1 > 0) {
            setContestId(contestParam.id);
            getContestData(contestParam.id);

            let interval = setInterval(() => {
                getContestData(contestParam.id, false);
            }, 10000);
            return () => {
                clearInterval(interval);
            };

        }
    }, [contestParam]);

    const getContestData = async (id, showRefresh = true) => {

        if(showRefresh) {
            setContestData(false);
            setContestParticipants(false);
        }
        
        try {

            const finalRes = await Promise.all([
                callAPI("readCoinflipCA/contests/" + id),
                callAPI("readCoinflipCA/getContestParticipants/" + id)
            ]);

            if(finalRes[0].status && !finalRes[0].data.error) {
              if(finalRes[0].data.result.presetId*1 === 0) {
                toast.error('Invalid Contest');
                return;
              }
              setContestData(finalRes[0].data.result);
            } else {
              throw(finalRes[0].data.error);
            }

            if(finalRes[1].status && !finalRes[1].data.error) {
                setContestParticipants(finalRes[1].data.result[0]);
            } else {
              throw(finalRes[1].data.error);
            }

            return {
                contestData: finalRes[0].data.result,
                participantsData: finalRes[1].data.result[0]
            }

        } catch (e) {
            toast.error(e)
        }

    }

    const joinContest = async (callerCalled) => {
        
        if(!callerCalled) {
            chooseCallModal.open();
            return;
        }

        setBlockDiv('joinBtn');
        // DO prelim checks
        try {

            const res = await callAPI("coinflipGame_JoinContest", {inputs: [ contestData.presetId, callerCalled.toString() ]});
            if(res.status && res.data.state*1 === 1) {
                pendingModal.open(res.data.hash);
                await joinContestCB(res.data.hash);
                pendingModal.close();
            } else {
                console.log(res);
                throw(res.data.error);
            }

        } catch (error) {
            toast.error(error);
        } finally {
            setBlockDiv('');
        }

    }

    const joinContestCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    const contestRes = await getContestData(contestId);
                    showPostContestJoinedModal(contestRes);
                } else if(pollRes.data.state == "failed") {
                    toast.error("Transaction Failed.");
                }
             })();
        });
    }

    const showPostContestJoinedModal = async (contestRes) => {

        //check if the event is winner or just contest join
        if(contestRes.contestData.winnerDeclared) {
            //Determine whether heads or tails
            let tossArr = ['Heads', 'Tails'];
            if(contestRes.contestData.callerCalled*1 === 2) {
                tossArr = ['Tails', 'Heads'];
            }
            const winningToss = tossArr[contestRes.contestData.winnerKey*1];

            //Check if the address is this account's address.
            if(contestRes.participantsData[contestRes.contestData.winnerKey*1].toLowerCase() === auth?.userBootstrap?.wallet?.address.toLowerCase()) {
                //If this address then show winner
                infoModal.open({
                    title: "It's " + winningToss + "! You WON!", 
                    description: 'Congratulations! Your winning amount from contest #' + contestId + ' is credited in your wallet.',
                    icon: <TrophyIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                    showConfeti: true
                });
            } else {
                //If not show better luck next time
                infoModal.open({
                    title: "It's " + winningToss + "! Caller WON.", 
                    description: 'Results declared for contest #' + contestId + '. Better luck next time.',
                    icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-400' />
                });
            }
            await auth.getAppBootstrap();
        } else {
            infoModal.open({
                title: "Contest #" + contestId + " Joined Successfully", 
                description: 'Please wait for another user to toss and declare results.',
                icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-400' />
            });
            await auth.getAppBootstrap();
        }
    }

    //Cancel and refund contest
    const refundContest = async (confirm = false) => {
        
        if(!confirm) {
            appDialog.open({
                header: 'Confirm Action?',
                description: 'Are you sure you want to cancel this contest. You will receive 100% of your entry fee as refund.',
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'Confirm',
                        color: 'red',
                        action: () => { refundContest(true); appDialog.close(); },
                    },
                    {
                        text: 'Cancel',
                        variant: 'outlined'
                    }
                ]
            });
            return;
        }

        setBlockDiv('refund');
        // DO prelim checks
        try {

            const res = await callAPI("coinflipGame_RefundContest", {inputs: [ contestId ]});
            if(res.status && res.data.state*1 === 1) {
                pendingModal.open(res.data.hash);
                await refundContestCB(res.data.hash);
                pendingModal.close();
            } else {
                console.log(res);
                throw(res.data.error);
            }

        } catch (error) {
            toast.error(error);
        } finally {
            setBlockDiv('');
        }

    }

    const refundContestCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    infoModal.open({
                        title: "Contest Cancelled and Refunded.", 
                        description: 'Contest has been cancelled successfully and entry fee is refunded to your wallet.',
                        icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                    });
                } else if(pollRes.data.state == "failed") {
                    toast.error("Transaction Failed.");
                }
             })();
        });
    }

    return (
        <>
            <div className="space-y-10">
                <div>
                    <div className="mb-6">
                        <TitleBase>CoinFlip Game</TitleBase>
                        <TitleSub>Player vs player and Powered by Blockchain</TitleSub>
                    </div>
                    <div className="-mr-[36px] -mb-7">
                        <GamewidgetCarousel openFirstContest={contestParam.id*1 > 0 ? false : true } />
                    </div>
                </div>
                {(contestData && contestParticipants) ?
                    <div>
                        <TitleBase>Contest #{contestId}</TitleBase>
                        <div className="sm:max-w-[500px] md:max-w-[750px] grid sm:grid-cols-[1fr_100px_1fr] gap-6 mx-auto mb-14 mt-16 md:mt-24">
                            <JoinedUserInfo contest={contestData} participant={contestParticipants.length > 0 ? contestParticipants[0] : false} allParticipants={contestParticipants} type={0} joinContest={joinContest} blockDiv={blockDiv} />
                            <div className="flex items-center justify-center">
                                <img src={vs} alt="verces" />
                            </div>
                            <JoinedUserInfo contest={contestData} participant={contestParticipants.length > 1 ? contestParticipants[1] : false} allParticipants={contestParticipants} type={1} joinContest={joinContest} blockDiv={blockDiv} />
                        </div>
                        <div className="flex items-center justify-center my-8">
                            <div className="py-1 flex items-center">
                                <p className="text-base sm:text-[18px] font-ibm-plex-sans font-medium text-white mr-5 text-end leading-tight">
                                    Entry <br /> Fee
                                </p>
                                <strong className="text-4xl sm:text-[40px] font-ibm-plex-sans font-bold leading-tight text-[#F8C571] block">
                                    <helpers.AmountToCurrency amount={contestData.entryFee/100} decimals={0} />
                                </strong>
                            </div>
                            <div className="bg-[#61636F] h-[60px] w-[1px] mx-4"></div>
                            <div className="py-1 flex items-center">
                                <strong className="text-4xl sm:text-[40px] font-ibm-plex-sans font-bold leading-tight text-[#F8C571] block">
                                    <helpers.AmountToCurrency amount={(contestData.entryFee*2-contestData.entryFee*2*auth.systemSettings.COINFLIP_ADMIN_FEE/100)/100} decimals={1} />
                                </strong>
                                <p className="text-base sm:text-[18px] font-ibm-plex-sans font-medium text-white ml-5 text-start leading-tight">
                                    Winner <br /> Gets
                                </p>
                            </div>
                        </div>
                        {contestData.takeFee && 
                            <div className='flex justify-center items-center align-middle gap-4 bg-white/90 max-w-sm mx-auto px-5 py-3 mb-7 shadow-md rounded-10'>
                                <img src={incomeImg} className='w-[40px]' />
                                <p className='font-ibm-plex-sans text-sm leading-[18px] text-dark'>Earn upto <b className='text-[#84622b]'><helpers.AmountToCurrency amount={contestData.entryFee*2*auth.systemSettings.COINFLIP_TO_REFERRER*2/10000} /></b> when your referrals participate in this contest. Doesn't matter whether they WIN or LOSE.</p>
                            </div>
                        }
                        <div className="grid sm:grid-cols-[90px_auto] items-center gap-3 max-w-[600px] mx-auto p-5 sm:py-7 sm:px-8 cursor-pointer border-4 border-primary/40 rounded-10 shadow-[0px_3px 10px_0px_rgba(0,0,0,0.10)] mb-6">
                            <div>
                                <img src={fairImg} className='w-[69px]'/>
                            </div>
                            <div>
                                <h4 className="text-primary font-ibm-plex-sans font-bold text-lg sm:text-xl mb-[5px]">Ensuring 100% Fair Game</h4>
                                <p className="text-[#AA9BD5] text-sm font-ibm-plex-sans font-normal mb-1">Experience fairness like never before in our contests. With results declared through decentralized smart contracts, you can trust that every outcome is transparent, unbiased, and guaranteed by cutting-edge blockchain technology.</p>
                                <a href={appConfig.scanURL + 'address/' + auth.systemSettings.COINFLIPGAME_CA + '#code'} target='blank' className="inline-block font-ibm-plex-sans font-bold text-base text-[#b6b0c6] transition hover:text-[#fff]">
                                    View Smart Contract
                                </a>
                            </div>
                        </div>
                        {!contestData.refunded && (contestParticipants.length === 0 || (contestParticipants.length === 1 && contestParticipants[0] == auth?.userBootstrap.wallet.address)) &&
                            <div className={`grid sm:grid-cols-[90px_auto] items-center gap-3 max-w-[600px] mx-auto p-5 sm:py-7 sm:px-8 border-4 font-ibm-plex-sans bg-gray-900/90 border-red-400 rounded-10 shadow-[0px_3px 10px_0px_rgba(0,0,0,0.10)] mb-7 ${contestData.callerCalledOn === '0' ? 'opacity-40 pointer-events-none' : 'opacity-100'}`}>
                                <div>
                                    <img src={refundImg} className='w-[69px]'/>
                                </div>
                                <div>
                                    <div className='text-lg sm:text-xl font-bold text-red-400 mb-1'>Cancel Contest and get refund</div>
                                    <div className='text-md sm:text-sm text-white/60'>You will receive 100% of your entry fee as refund to your wallet</div>
                                    {contestData.callerCalledOn*1 + auth?.systemSettings?.COINFLIP_SECONDS_TILL_REFUND*1 > Date.now()/1000 ?
                                        <Button color='red' variant='outlined' size='sm' disabled className='mt-3'>Claim <Timestamp date={contestData.callerCalledOn*1 + auth.systemSettings.COINFLIP_SECONDS_TILL_REFUND*1} relative/></Button>
                                    :
                                        <Button color='red' loading={blockDiv === 'refund'} disabled={blockDiv === 'refund'} className='mt-3' size='sm' onClick={() => refundContest(false)}>Cancel Now</Button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                :
                    <LoadingData />
                }
            </div>
            <div className=''>
                <CoinflipGameHistory refreshCallback={contestData} />
            </div>
            <PendingModal hook={pendingModal} />
            <AppDialog hook={appDialog} />
            <InfoModal hook={infoModal} />
            <AppModal Component={ChooseCallModal} props={{ joinContest: joinContest, dismiss: chooseCallModal.close }} hook={chooseCallModal} />
        </>
    );
};
export default CoinflipGame;