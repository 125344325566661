import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import Avatar from 'react-avatar';
import Timestamp from 'react-timestamp';
import { useHelpers } from '../../../Hooks/helpers';

const MyReferralsItem = ({ data }) => {

    const helpers = useHelpers();

    return (
        <tr>
            <td className='font-bold flex align-middle items-center space-x-2'><Avatar size='27' round src={data.avatar} /> <p>{data.username}</p> {data.country && <Tooltip content={data.country}><img src={'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + data.country  + '.svg'} className='w-[18px] rounded-[3px]' /></Tooltip>}</td>
            <td className='font-bold'><helpers.AmountToCurrency amount={data.wallet_earned} /></td>
            <td><div className='font-bold'><helpers.AmountToCurrency amount={data.cash_earned} type='cash' iconSize={19} /></div></td>
            <td className='flex justify-end'><helpers.TimestampAndData created={data.created} /></td>
        </tr>
    )
}

export default React.memo(MyReferralsItem);