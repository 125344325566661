import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { AlertTriangle, Check, X } from 'react-feather';
import { toast } from 'react-hot-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAppState } from '../../../Contexts/appStateContext';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from '../../../Hooks/callAPI';
import { useHelpers } from '../../../Hooks/helpers';
import { useSurfAdVideoBox } from '../../../Hooks/watchAds/VideoBox';
import SurfAdIFrameBox from './IFrameBox';
import SurfAdVideoBox from './VideoBox';
import { useSurfAdCounter } from '../../../Hooks/watchAds/Counter';
import { SurfAdCounter } from './Counter';
import { useSurfAdDetail } from '../../../Hooks/watchAds/AdDetail';
import { useSurfAdCaptcha } from '../../../Hooks/watchAds/Captcha';
import { SurfAdDetail } from './AdDetail';
import { SurfAdCaptcha } from './Captcha';
import { useAppDialog } from '../../../Hooks/appDialog';
import { AppDialog } from '../../modals/AppDialog';
import cashImg from '../../../Theme/assets/img/cash.png';

export const SurferModal = ({ ad, surferHook, refreshCallback, creditAdFn, creditAdAddParam = [], isPreview = false }) => {

    const [media, setMedia] = useState(ad.media_url);

    const adDetail = useSurfAdDetail();
    const counter = useSurfAdCounter();
    const videoBox = useSurfAdVideoBox();
    const captcha = useSurfAdCaptcha();

    const appState = useAppState();
    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const appDialog = useAppDialog();
    
    //Pause timer baesd on app focus
    useEffect(() => {
        if(!appState?.appInFocus) {
            counter.setIsPaused(true);
            videoBox.setVideoPaused(true);
        } else {
            if(!counter.ended && counter.isPaused) {
                counter.setIsPaused(false);
                videoBox.setVideoPaused(false);
            }
        }
    }, [appState?.appInFocus]);

    //Credit ad end
    useEffect(() => {
        if(counter.ended && captcha.captchaSolved) {
            !isPreview && creditAd();
        } else if(counter.ended && !captcha.captchaSolved) {
            toast.error("Wrong Captcha");
            adDetail.setAdCreditedRow(false);
        }
    }, [counter.ended, captcha.captchaSolved]);

    const creditAd = async () => {
        
        const res = await callAPI(creditAdFn, { adId: ad.id, conversion: adDetail.conversionRecorded, ...creditAdAddParam });
        
        if(res.status && res.data.state*1 === 1) {
            adDetail.setAdCreditedRow(res.data.record);
            await auth?.getAppBootstrap();
            appDialog.open({
                icon: cashImg,
                header: 'Cash Credited',
                description: 'Earned amount is credited to your cash balance. You may continue watching or close this ad to watch next.',
                backdropDismiss: true,
                buttons: [
                    {
                        text: 'Continue watching this ad',
                        color: 'green'
                    },
                    {
                        text: 'Close',
                        color: 'red',
                        action: closeSurfAdModal,
                    }
                ]
            });
        } else {
            toast.error(res.data.error);
            adDetail.setAdCreditedRow(false);
        }
    }

    const closeSurfAdModal = () => {
        counter.stop();
        videoBox.setVideoPaused(true);
        adDetail.close(false);
        captcha.setDismissCaptcha(true);
        setMedia(null);
        !isPreview && refreshCallback();
        surferHook.close();
        appDialog.close();
    }

    const handleConfirmCloseAd = () => {

        if(!counter.ended) {
            appDialog.open({
                header: 'Confirm Close?',
                description: 'Are you sure you want to close this ad. You will not receive any earnings from this ad.',
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'Confirm',
                        color: 'red',
                        action: closeSurfAdModal,
                    },
                    {
                        text: 'Cancel',
                        variant: 'outlined'
                    }
                ]
            });
        } else {
            closeSurfAdModal();
            !isPreview && refreshCallback();
        }
    }

    return (
        <>
            <Transition appear show={surferHook.isOpen} as={Fragment}>
                <Dialog as="div" onClose={() => null} className="relative z-[4]">
                    <div className="bg-white fixed inset-0 w-full h-full">
                        <div className='bg-yellow-300 opacity-80 z-10 rounded-full w-[60px] h-[60px] absolute right-5 top-5'>
                            <CircularProgressbarWithChildren 
                                value={counter.percent} 
                                maxValue={1}
                                styles={buildStyles({
                                    pathColor: `rgba(0,0,0, ${counter.percent})`,
                                    trailColor: '#ffffff',
                                })}
                            >
                                <>
                                    {!counter.ended && 
                                        <SurfAdCounter timer={ad.timer} counterHook={counter} />
                                    }
                                    {counter.ended && captcha.captchaSolved && adDetail.adCreditedRow > 0 && 
                                        <Check onClick={() => toast.success('Ad credited. You can now close this ad.')} size={25} />
                                    }
                                    {counter.ended && adDetail.adCreditedRow === null && 
                                        <ClipLoader
                                            color="#333333"
                                            loading={true}
                                            size={20}
                                            aria-label="Loading"
                                            data-testid="loader"
                                            className="w-6"
                                        />
                                    }
                                    {counter.ended && !captcha.captchaSolved && adDetail.adCreditedRow === false && 
                                        <AlertTriangle onClick={() => toast.error('Wrong Captcha Entered')} size={25} />
                                    }
                                </>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div onClick={handleConfirmCloseAd} className={`${counter.ended ? 'bg-green-600': 'bg-red-600'} opacity-80 z-10 rounded-full p-2 absolute right-[28px] top-[90px] cursor-pointer`}>
                            <X size="25" color='#ffffff' />
                        </div>

                        {ad && ad.media === "website" && 
                            <SurfAdIFrameBox media={media} counterHook={counter} />
                        }
                        {ad && ad.media === "video" && 
                            <SurfAdVideoBox video={media} videoHook={videoBox} counterHook={counter} />
                        }

                        {ad && <SurfAdDetail ad={ad} adDetailsHook={adDetail} counterHook={counter} />}
                        {ad && ad.showCaptcha === "yes" && <SurfAdCaptcha ad={ad} captchaHook={captcha} counterHook={counter} videoHook={videoBox} />}
                    
                    </div>
                </Dialog>
            </Transition>
            <AppDialog hook={appDialog} />
        </>
    );
}