import { Avatar } from '@material-tailwind/react';
import React, { useEffect } from 'react';
import { ChevronRight, X } from 'react-feather';

export const SurfAdDetail = ({ ad, adDetailsHook, counterHook }) => {

    //Show popup at last
    useEffect(() => {
        if(counterHook.percent < 2 && counterHook.percent > 0.9) {
            adDetailsHook.setIsOpen(true);
        }
    }, [counterHook.percent]);

    const recordConversion = async () => {
        if(adDetailsHook.adCreditedRow > 0) {
            await callAPI("recordSurfAdConversion", { adCreditedRow: adDetailsHook.adCreditedRow });
        } else if(adDetailsHook.adCreditedRow === null) {
            adDetailsHook.setConversionRecorded(true);
        }
    }

    return (
        <>
            {ad &&
                <div className={`
                    bg-gray-200 opacity-90 z-20 absolute bottom-0 left-0 m-5 shadow-[0_4px_4px_0px_rgb(0,0,0,0.15)] max-w-full items-center
                    ${!adDetailsHook.isOpen ? 'rounded-full grid grid-cols-[50px_auto] border-3 border-blue-600' : 
                    'rounded-10 py-3 px-4 grid grid-cols-[30px_auto_auto] gap-3 sm:gap-3'}
                `}>
                    <div className={`text-center space-y-2 ${adDetailsHook.isOpen ? '' : 'overflow-hidden rounded-full'}`}>
                        <Avatar size={adDetailsHook.isOpen ? 'md' : 'lg'} src={ad.thumbnail} alt={ad.title} />
                    </div>
                    {adDetailsHook.isOpen ?
                        <>
                            <div>
                                <h5 className="text-sm font-ibm-plex-sans text-gray-900 font-bold">
                                    {ad.title}
                                </h5>
                                <p className="text-xsm font-ibm-plex-sans text-gray-700 ">
                                    {ad.description}
                                </p>
                            </div>
                            <div className="items-center justify-end space-x-3">
                                <a
                                    href={ad.target_url} 
                                    target="_blank" 
                                    onClick={recordConversion}
                                    className="px-3 py-2 bg-primary hover:bg-[#392B2B] transition duration-200 rounded-[5px] text-gray-900 hover:text-white font-ibm-plex-sans font-bold text-xsm"
                                >
                                    {ad.button_text}
                                </a>
                            </div>
                            <div className="absolute -right-1 -top-1 bg-red-600 rounded-full p-[3px] text-white cursor-pointer">
                                <X size={12} onClick={adDetailsHook.close} />
                            </div>
                        </>
                    :
                        <div className="absolute -right-3 bg-blue-600 rounded-[5px] p-1 text-white cursor-pointer">
                            <ChevronRight size={15} onClick={adDetailsHook.open} />
                        </div>
                    }
                </div>
            }
        </>
    )
}