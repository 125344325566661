import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { ArrowDownCircle } from 'react-feather';
import { toast } from "react-hot-toast";
import { PuffLoader } from "react-spinners";
import TransferHistory from "../../Components/dash/transferCash/transferHistory";
import { InfoModal } from "../../Components/modals/InfoModal";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { useInfoModal } from "../../Hooks/infoModal";
import { usePollAPI } from "../../Hooks/pollAPI";
import { MediumDarkButton } from '../../Utility/Buttons';
import { TitleBase } from "../../Utility/Utility";

const TransferCash = () => {
    
    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
    const infoModal = useInfoModal();

    const [blockDiv, setBlockDiv] = useState(false);

    const startTransferCash = async () => {

        setBlockDiv(true);
        try {
            if(auth?.userBootstrap?.userData.cash_balance*1 < auth?.systemSettings.TRANSFERCASH_MINIMUM) {
                throw('Minimum cash balance required : $' + auth?.systemSettings.TRANSFERCASH_MINIMUM);
            }
            const res = await callAPI("createTransferCash/contests/");
            if(res.status && res.data.state*1 === 1) {
                //Check if instant withdrwaals enabled, Else show withdraw reqest received
                if(auth?.systemSettings.TRANSFERCASH_INSTANT_PROCESS === 'yes') {
                    infoModal.open({
                        title: "Transfer In Progress", 
                        description: 'Your request is in progress and will be credited to your account wallet in 1-2 minutes. Once credited you can withdraw it to your external wallet address or use it to play games.',
                        icon: <PuffLoader
                            color={'#F1BE6B'}
                            loading={true}
                            size={70}
                            aria-label="Loading"
                            data-testid="loader"
                            className="mx-auto mb-5"
                        />,
                        showConfeti: false,
                        hideButton: true,
                        backdropDND: true
                    });
                    await checkOracleCompleted(res.data.rowId);
                    infoModal.close();
                    infoModal.open({
                        title: "Cash Transfer Complete", 
                        description: 'Your request is processed successfully! You can now withdraw cash to your external wallet address or play games to win.',
                        icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                        showConfeti: true
                    });
                    await auth.getAppBootstrap();
                } else {
                    infoModal.open({
                        title: "Transfer request received", 
                        description: 'Your request is in progress and will be credited to your account wallet in 24-48 hours. Once credited you can withdraw it to your external wallet address.',
                        icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                        showConfeti: false
                    });
                }
            } else {
                throw(res.data.error);
            }
        } catch (e) {
            toast.error(e);
        } finally {
            setBlockDiv(false);
        }
    }

    const checkOracleCompleted = (id) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkTransferCashTxn/" + id);
                const pollContinueCondition = (res) => res.data.state === "pending" || res.data.state === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "failed") {
                    toast.error("Failed to transfer. Contact support for more info.");
                }
             })();
        });
    }

    return (
        <>
            <div className="mb-3">
                <div className={`space-y-6`}>
                    <div className="bg-gradient-one shadow-xl rounded-10 p-8 pb-5 text-center max-w-xl mx-auto">
                        <h5 className="text-darker font-ibm-plex-sans font-bold text-base_next leading-tight mb-4">
                            Transfer Cash to Account Wallet
                        </h5>
                        <div className="text-darkest text-5xl font-ibm-plex-sans font-bold leading-tight">
                            <helper.AmountToCurrency amount={auth?.userBootstrap?.userData.cash_balance} decimals={3} type='cash' iconSize={45} />
                        </div>
                        <span className="text-darker font-ibm-plex-sans font-medium text-base_next leading-tight block">
                            Available Cash Balance
                        </span>
                        <div className='mt-7'>
                            <MediumDarkButton onClick={startTransferCash} disabled={blockDiv} isloading={blockDiv ? 1 : 0}>
                                Transfer All
                                <ArrowDownCircle size={25} />
                            </MediumDarkButton>
                        </div>
                        <div className='mt-4 text-sm flex space-x-6 font-ibm-plex-sans align-middle justify-between'>
                            <div className="text-left">$ equivalent BNB will be transferred to your account wallet instantly by the Smart Contract.</div>
                            <div className="text-right min-w-[30%]">Oracle fee<p className="font-bold">{auth?.systemSettings?.TRANSFERCASH_FEE_PRECENT}%</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="my-5">
                    <TitleBase>Cash Transfer History</TitleBase>
                </div>
                <TransferHistory />
            </div>
            <InfoModal hook={infoModal} />
        </>
    );
};
export default TransferCash;