import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';
import * as Yup from 'yup';
import { useCallAPI } from '../../../Hooks/callAPI';
import { SmallPrimaryButton } from '../../../Utility/Buttons';
import UploadFile from '../../../Utility/UploadFile';
import { FormLabel } from '../../../Utility/Utility';

const ChangeProfilePic = ({ auth }) => {
    
    const callAPI = useCallAPI();

    const [blockDiv, setBlockDiv] = useState(false);
    const [uploading, setUploading] = useState(false);

    const defaultValues = {
        avatar: auth?.userBootstrap?.userData?.avatar
    };

    const validationSchema = Yup.object().shape({});

    const {
        register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
    } = useForm({ defaultValues: defaultValues, mode: 'onChange', resolver: yupResolver(validationSchema) })

    const onSubmit = async () => {

        setBlockDiv(true);
        const formData = getValues();
        const res = await callAPI("updateUserProfilePic", formData);
        if(res.data.state === 1) { //Success
            toast.success("Profile Updated");
            auth?.getAppBootstrap();
            setBlockDiv(false);
        } else { //Handle error
            setBlockDiv(false);
            toast.error(res.data.error);
            console.log(res.data);
        }

    }

    const handleUploadSuccess = (target, url) => {
        setValue(target, url);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            
            {auth?.userBootstrap?.userData?.avatar && auth?.userBootstrap?.userData?.avatar === getValues().avatar && 
                <div className="text-left mb-4">
                    <Avatar size='50' className='rounded-10' src={auth?.userBootstrap?.userData?.avatar} name={auth?.userBootstrap?.userData?.username} alt={auth?.userBootstrap?.userData?.username} />
                </div>
            }
            
            <div className="sm:grid grid-cols-2 items-center pb-3">
                <FormLabel htmlFor="media" helper="Choose an image for your profile. (Squared image preferred)">Choose Profile Picture</FormLabel>
                <div className='mt-3 sm:mt-0 flex justify-end items-center'>
                    <UploadFile type='avatar' fileSize={auth?.systemSettings?.UPLOAD_AVATAR_LIMIT_BYTES*1} allowedTypes="image/jpg, image/png, image/jpeg, image/gif" setUploading={setUploading} handleUploadSuccess={handleUploadSuccess} folder="Avatar"  />
                    {uploading && <ClipLoader
                        color={'#F8C571'}
                        loading={true}
                        size={20}
                        aria-label="Loading"
                        data-testid="loader"
                        className={`ml-2`}
                    />}
                </div>
            </div>
            <div className='mt-4'>
                <SmallPrimaryButton type="submit" disabled={!isValid || blockDiv} isloading={blockDiv ? 1 : 0}>Save Changes <ArrowRightCircleIcon width={23} /></SmallPrimaryButton>
            </div>
        </form>
    );
};
export default ChangeProfilePic;