import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import promoImage from "../../../Theme/assets/img/promo.png";

const PromoBanner = ({ imgUrl }) => {
  return (
    <div className="slide_item mr-4 md:mr-6">
      <img
        src={imgUrl}
        alt="promo"
        className="rounded-10 shadow-[0_3px_10px_0_rgb(0,0,0,0.10)] w-full max-w-full"
      />
    </div>
  );
};

const promoArray = [
  {
    imgUrl: promoImage,
  },
  {
    imgUrl: promoImage,
  },
  {
    imgUrl: promoImage,
  },
  {
    imgUrl: promoImage,
  },
  {
    imgUrl: promoImage,
  },
];

const PromoCarousel = () => {
  const settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: "",
    centerMode: true,
    centerPadding: 150,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.5,
          centerPadding: 130,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1.8,
          centerPadding: 150,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          centerPadding: 80,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.1,
          centerPadding: 30,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {promoArray.map((item, index) => (
        <PromoBanner key={index} imgUrl={item.imgUrl} />
      ))}
    </Slider>
  );
};

export default PromoCarousel;