//Import CSS
import { PuffLoader } from "react-spinners";
import dashboardBg from "../Theme/assets/img/dashboard-bg.png";

export default function LayoutLoading() {  
    return (
        <div className="bg-[#0B002B] min-h-screen relative font-khand px-4 pb-4 md:p-6 pt-4 overflow-hidden">
            <div
                className="w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0"
                style={{ backgroundImage: `url(${dashboardBg})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}
            >
                <span className="w-full h-full bg-one block absolute top-0 left-0 right-0 bottom-0"></span>
            </div>
            <div className="grid h-screen place-items-center">
                <PuffLoader
                    color={'#F1BE6B'}
                    loading={true}
                    size={70}
                    aria-label="Loading"
                    data-testid="loader"
                />
            </div>
        </div>
    );
}