import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import React from "react";
import appConfig from "../../Utility/Config/config";

const NeedHelp = () => {
  return (
    <a href={appConfig.supportLink} target="blank">
      <div className="md:max-w-[350px] ml-auto bg-lighter/80 shadow-xl md:rounded-l-[20px] px-9">
        <div className="flex items-center justify-center mx-auto py-9 space-x-5 cursor-pointer">
          <ChatBubbleBottomCenterTextIcon className="w-[100px] text-darkest" />
          <div>
            <h4 className="text-darkest font-ibm-plex-sans font-bold text-2xl mb-[2px]">
              Need help?
            </h4>
            <p className="text-darkest text-sm leading-[18px] font-ibm-plex-sans font-normal">
              Click here to reach out to our helpdesk for any issues related to dexloot.
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default NeedHelp;
