import { Button } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import StatisticsAds from "../../../Components/dash/watchAds/StatisticsAds";
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from '../../../Hooks/callAPI';
import { useCallInfiniteResultsAPI } from '../../../Hooks/callInfiniteResultsAPI';
import { useHelpers } from '../../../Hooks/helpers';
import { EmptyRecords, LoadingData, TitleBase } from '../../../Utility/Utility';
import CoinflipGameHistoryItem from './CoinflipGameHistoryItem';

const CoinflipGameHistory = ({ refreshCallback }) => {

    const auth = useUserAuth();
    const infiniteResults = useCallInfiniteResultsAPI();
    const callAPI = useCallAPI();
    const helper = useHelpers();

    const [stats, setStats] = useState(false);

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            infiniteResults.getResults({ apiURL: "getUserCoinflipContests", reset: true, sortByDate: false });
            getCoinflipStats();
        }
    }, [auth?.appBootstrapped, refreshCallback]);

    const getCoinflipStats = async () => {

        const res = await callAPI("getUserCoinflipUserStats");      
        if(res.status && res.data) {
            setStats(res.data);
        } else {
            setStats({});
        }
    }

    return (
        <div className=''>
            {stats &&
                <div className="max-w-xl mx-auto grid sm:grid-cols-2 gap-4 mb-6">
                    <StatisticsAds
                        total={stats.today.total}
                        price={helper.AmountToCurrency({ amount: stats.today.amount })}
                        text="Played Today"
                        styles={`bg-gradient-eleven`}
                    />
                    <StatisticsAds
                        total={stats.lifetime.total}
                        price={helper.AmountToCurrency({ amount: stats.lifetime.amount })}
                        text="Played Lifetime"
                        styles={`bg-gradient-eleven`}
                    />
                </div>
            }
            <div className="flex justify-between align-middle items-center my-5">
                <TitleBase>Coinflip Contests History</TitleBase>
                <Button color='amber' size='sm' onClick={() => infiniteResults.getResults({ apiURL: "getUserCoinflipContests", reset: true, sortByDate: false })} loading={infiniteResults.loading} disabled={infiniteResults.loading}>Refresh</Button>
            </div>
            {infiniteResults.data && infiniteResults.data.length > 0 && 
                <div>
                    <div className="table-custom shadow-md">
                        <table className="w-full font-ibm-plex-sans text-[#dbd8d8]">
                            <thead>
                                <tr className="shadow-sm">
                                    <th>Contest #<br/>Entry</th>
                                    <th>Caller</th>
                                    <th>Tosser</th>
                                    <th>Called On<br/>Hash</th>
                                    <th>Tossed On<br/>Hash</th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>
                            {infiniteResults.data.map((e, i) => {
                                return (
                                    <CoinflipGameHistoryItem data={e} key={i} />
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    {!infiniteResults.loadFull &&
                        <Button size='sm' color='blue' onClick={() =>  infiniteResults.getResults({ apiURL: "getUserCoinflipContests", reset: false, sortByDate: false })} loading={infiniteResults.loading} disabled={infiniteResults.loading}>Load More</Button>
                    }
                    {infiniteResults.loadFull &&
                        <Button size='sm' color='white' className='mt-2' disabled>All Rows Loaded</Button>
                    }
                </div>
            }
            
            {!infiniteResults.data &&
                <LoadingData />
            }

            {infiniteResults.data && infiniteResults.data.length === 0 && 
                <EmptyRecords title="No Activity Found" description="Your coinflip game contests will appear here" />
            }
        </div>       
    )
}

export default CoinflipGameHistory;