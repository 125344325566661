import { Button } from '@material-tailwind/react';
import React, { useEffect } from 'react';
import Timestamp from 'react-timestamp';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallInfiniteResultsAPI } from '../../../Hooks/callInfiniteResultsAPI';
import { EmptyRecords, LoadingData } from '../../../Utility/Utility';
import WithdrawalsHistoryItem from './WithdrawalsHistoryItem';

const WithdrawalsHistory = () => {

    const auth = useUserAuth();
    const infiniteResults = useCallInfiniteResultsAPI();

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            infiniteResults.getResults({ apiURL: 'getUserWithdrawalsRecords', reset: true });

            let interval = setInterval(() => {
                infiniteResults.getResults({ apiURL: 'getUserWithdrawalsRecords', reset: true });
            }, 10000);
            return () => {
                clearInterval(interval);
            };

        }
    }, [auth?.appBootstrapped]);

    return (
        <div className=''>
            {infiniteResults.data && infiniteResults.data.length > 0 && 
                <>
                    {infiniteResults.data.map((data, k) => {
                        return (
                            <div key={k}>
                                <p className='text-sm text-light font-bold my-4'><Timestamp date={data[0].created} options={{ format:"date", includeDay: true }} /></p>
                                <div className="table-custom shadow-md">
                                    <table className="w-full font-ibm-plex-sans text-[#dbd8d8]">
                                        <thead>
                                            <tr className="shadow-sm">
                                                <th>Amount</th>
                                                <th>Created</th>
                                                <th>Equivalent BNB</th>
                                                <th>To Address</th>
                                                <th>Txn Hash</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.map((e, i) => {
                                            return (
                                                <WithdrawalsHistoryItem data={e} key={i} />
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    })}
                    {!infiniteResults.loadFull &&
                        <Button size='sm' color='blue' onClick={() =>  infiniteResults.getResults({ apiURL: 'getUserWithdrawalsRecords', reset: false })} loading={infiniteResults.loading} disabled={infiniteResults.loading}>Load More</Button>
                    }
                    {infiniteResults.loadFull &&
                        <Button size='sm' color='white' className='mt-2' disabled>All Rows Loaded</Button>
                    }
                </>
            }
            
            {!infiniteResults.data &&
                <LoadingData />
            }

            {infiniteResults.data && infiniteResults.data.length === 0 && 
                <EmptyRecords title="No withdrawals Found" description="Your withdrawals will appear here" />
            }
        </div>       
    )
}

export default WithdrawalsHistory;