import { useState } from 'react';

export const useSurfAdCounter = () => {

    const [seconds, setSeconds] = useState(0);
    const [percent, setPercent] = useState(0);
    const [ended, setEnded] = useState(false);
    const [isPaused, setIsPaused] = useState(null);

    const stop = () => {
        setSeconds(0);
        setPercent(0);
        setEnded(false);
        setIsPaused(null);
    }

    return { 
        seconds,
        isPaused, 
        ended,
        percent,
        stop,
        setSeconds,
        setIsPaused,
        setEnded,
        setPercent
    };
    
}