import Confetti from 'react-confetti';
import { BottomSheet } from 'react-spring-bottom-sheet';
import useWindowSize from 'react-use/lib/useWindowSize';

export const InfoModal = ({ hook }) => {
    const { width, height } = useWindowSize();
    return (
        <BottomSheet open={hook.isOpen} onDismiss={hook?.data?.backdropDND ? null : hook.close}>
            <div className='items-center text-center py-10 px-10'>
                {hook.data && 
                    <>
                        {hook.data.showConfeti &&  <Confetti width={width} height={height} />}
                        {hook.data.icon && <>{hook.data.icon}</> }
                        {hook.data.title && <h1 className="text-2xl sm:text-3xl text-dark mb-3 font-viga font-normal">{hook.data.title}</h1>}
                        {hook.data.description && <p className="text-base sm:text-lg text-[#777] max-w-[390px] mx-auto font-ibm-plex-sans font-normal mb-5">{hook.data.description}</p>}
                        {hook.data.buttonData && <button onClick={hook.data.buttonData.onClick} className={`bg-gradient-three text-center rounded-10 text-md sm:text-sm text-black font-ibm-plex-sans font-semibold px-8 py-3 mx-1`}>{hook.data.buttonData.text}</button>}
                        {hook.data.hideButton ? <></> : <button onClick={hook.data.onClick ? hook.data.onClick : hook.close} className={`bg-gradient-four text-center rounded-10 text-md sm:text-sm text-white font-ibm-plex-sans font-semibold px-8 py-3 mx-1`}>{hook.data.onClickText ? hook.data.onClickText : 'Close'}</button>}
                    </>
                }
            </div>
        </BottomSheet>
    )
}
