import { IconButton } from '@material-tailwind/react';
import React from 'react';
import { AlertTriangle, CheckCircle, Loader } from 'react-feather';
import Timestamp from 'react-timestamp';
import { useHelpers } from '../../../Hooks/helpers';

const TransferHistoryItem = ({ data }) => {

    const helpers = useHelpers();

    const renderStatus = [];
    renderStatus['pending'] = {
        icon: <Loader size={16} className='animate-spin' />,
        color: 'white'
    };
    renderStatus['processing'] = {
        icon: <Loader size={16} className='animate-spin' />,
        color: 'blue'
    };
    renderStatus['complete'] = {
        icon: <CheckCircle size={16} className=''/>,
        color: 'green'
    };
    renderStatus['failed'] = {
        icon: <AlertTriangle size={16} className=''/>,
        color: 'red'
    };

    return (
        <tr>
            <td className='font-bold'><helpers.AmountToCurrency amount={data.amount*1 + data.fees*1} type='cash' iconSize={21} /></td>
            <td className='font-bold'><helpers.AmountToCurrency amount={data.fees} type='cash' iconSize={21} /></td>
            <td className='flex justify-center'><helpers.TimestampAndData created={data.created} /></td>            
            <td><helpers.capitalizeString text={data.status} /> <IconButton size='sm' className='ml-1' color={renderStatus[data.status].color}>{renderStatus[data.status].icon}</IconButton></td>
        </tr>
    )
}

export default React.memo(TransferHistoryItem);