import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import pages
import Login from "../Pages/auth/Login";
import Logout from "../Pages/auth/Logout";
import Register from "../Pages/auth/Register";
import Forgot from "../Pages/auth/Forgot";
import Reset from "../Pages/auth/Reset";

import Dashboard from "../Pages/dash/Dashboard";
import Settings from "../Pages/dash/Settings";
import CoinflipGame from "../Pages/dash/CoinflipGame";
import WatchAds from "../Pages/dash/WatchAds";
import Offerwalls from "../Pages/dash/Offerwalls";
import TransferCash from "../Pages/dash/TransferCash";
import CashActivity from "../Pages/dash/CashActivity";
import Goals from "../Pages/dash/Goals";
import AddFunds from "../Pages/dash/AddFunds";
import WithdrawFunds from "../Pages/dash/WithdrawFunds";
import WalletActivity from "../Pages/dash/WalletActivity";
import Referrals from "../Pages/dash/Referrals";
import Notifications from "../Pages/dash/Notifications";
import OfferwallsHistory from "../Pages/dash/OfferwallsHistory";
// import PurchasePRO from "../Pages/dash/PurchasePRO";
// import CoinflipGame from "../Pages/dash/CoinflipGame";
// import WatchAds from "../Pages/dash/WatchAds";
// import CompleteTasks from "../Pages/dash/CompleteTasks";
// import WatchAdsCreateCampaign from "../Pages/dash/Advertise/WatchAds/Create";
// import WatchAdCampaigns from "../Pages/dash/Advertise/WatchAds/Campaigns";
// import WatchAdsManageCampaign from "../Pages/dash/Advertise/WatchAds/Manage";
// import WatchAdsEditCampaign from "../Pages/dash/Advertise/WatchAds/Edit";
// import Offerwalls from "../Pages/dash/Offerwalls";
// import PredictRPOMatches from "../Pages/dash/PredictRPOMatches";
// import PredictRPOMatch from "../Pages/dash/PredictRPOMatch";
// import WithdrawCash from "../Pages/dash/WithdrawCash";
// import Settings from "../Pages/dash/Settings";
// import CashActivity from "../Pages/dash/CashActivity";

export default function AppRouter() {
  return (
    <Routes>
      
        {/* Authentication Routes */}
        <Route path="/register" element={<Register />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/forgot" element={<Forgot />}></Route>
        <Route path="/reset" element={<Reset />}></Route>

        {/* //Referral LInk */}
        <Route path="/r/:ref" element={<Register />}></Route>

        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>

        {/* Settings */}
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/notifications" element={<Notifications />}></Route>

        {/* Coinflip Routes */}
        <Route path="/coinflipGame" element={<CoinflipGame />}></Route>
        <Route path="/coinflipGame/:id" element={<CoinflipGame />}></Route>

        {/* WatchAd Routes */}
        <Route path="/watchAds" element={<WatchAds />}></Route>

        {/* Goals Routes */}
        <Route path="/goals" element={<Goals />}></Route>
        <Route path="/goals/:id" element={<Goals />}></Route>

        {/* Offerwalls */}
        <Route path="/offerwalls" element={<Offerwalls />}></Route>
        <Route path="/offerwallsHistory" element={<OfferwallsHistory />}></Route>

        {/* Tranfer Cash */}
        <Route path="/transferCash" element={<TransferCash />}></Route>

        {/* Add Funds */}
        <Route path="/addFunds" element={<AddFunds />}></Route>

        {/* Withdraw Funds */}
        <Route path="/withdrawFunds" element={<WithdrawFunds />}></Route>

        {/* Referrals */}
        <Route path="/referrals" element={<Referrals />}></Route>

        {/* Activity */}
        <Route path="/cashActivity" element={<CashActivity />}></Route>
        <Route path="/walletActivity" element={<WalletActivity />}></Route>

    </Routes>
  );
}