import React, { useEffect, useState } from 'react';
import { useHelpers } from '../../../Hooks/helpers';
import { useCallAPI } from '../../../Hooks/callAPI';
import { FormToggle, LoadingData } from '../../../Utility/Utility';
import Toggle from 'react-toggle';
import toast from 'react-hot-toast';

const NotificationSettings = ({ auth }) => {

    const helper = useHelpers();
    const callAPI = useCallAPI();

    const [notifiSettings, setNotifSettings] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(auth?.appBootstrapped === true && auth?.loggedIn === true) {
            getResults();
        }
    }, [auth?.appBootstrapped]);

    const getResults = async () => {
        const res = await callAPI("getUserSettings");       
        if(res.status && res.data) {
            setNotifSettings(res.data);
        }
    }

    const updateSetting = async (e) => {
        setLoading(e.target.name);
        const formData = {
            [e.target.name]: e.target.checked
        };
        const res = await callAPI("updateUserSettings", formData);
        if(res.data.state === 1) { //Success
        } else { //Handle error
            toast.error(res.data.error);
            console.log(res.data);
        }
        setLoading(false);
    }

    return (
        <>
            {notifiSettings ? 
                <>
                    <FormToggle 
                        title='New referral joined' 
                        helper='Get notified when new referrals join using your invitation link.'
                        name='newReferral'
                        disabled={loading === 'newReferral'}
                        checked={notifiSettings?.newReferral}
                        onChange={updateSetting}
                    />
                    <FormToggle 
                        title='Contest related' 
                        helper='Get notified when you win/lose/complete a contest.'
                        name='contestStateChange'
                        disabled={loading === 'contestStateChange'}
                        checked={notifiSettings?.contestStateChange}
                        onChange={updateSetting}
                    />
                    <FormToggle 
                        title='Change in goal/task status' 
                        helper='Get notified when a task/task gets approved/validated/rejected.'
                        name='offerStateChange'
                        disabled={loading === 'offerStateChange'}
                        checked={notifiSettings?.offerStateChange}
                        onChange={updateSetting}
                    />
                    <FormToggle 
                        title='Withdrawal completed' 
                        helper='Get notified when your withdrawal is processed.'
                        name='withdrawalStateChange'
                        disabled={loading === 'withdrawalStateChange'}
                        checked={notifiSettings?.withdrawalStateChange}
                        onChange={updateSetting}
                    />
                    {/* <FormToggle 
                        title='Campaign related' 
                        helper='Get notified for all campaign related activities.'
                        name='campaignRelated'
                        disabled={loading === 'campaignRelated'}
                        checked={notifiSettings?.campaignRelated}
                        onChange={updateSetting}
                    /> */}
                </>
            : 
                <LoadingData />
            }
        </>
    );
};

export default NotificationSettings;