import { yupResolver } from '@hookform/resolvers/yup';
import { default as React, useState } from 'react';
import { ArrowRightCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import NeedHelp from '../../Components/auth/NeedHelp';
import WelcomeTitle from "../../Components/auth/WelcomeTitle";
import { useAppState } from '../../Contexts/appStateContext';
import { useCallAPI } from '../../Hooks/callAPI';
import loginBg from "../../Theme/assets/img/loginbg.jpg";
import { LargePrimaryButton } from "../../Utility/Buttons";
import { FormInputBig } from '../../Utility/Utility';

const Forgot = () => {
  
    const callAPI = useCallAPI();
    const history = useNavigate();
    const appState = useAppState();

    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState(false);

    const FormSchema = yup.object().shape({
      email: yup.string().min(5).max(100).label('Email Address').required('Please enter your email address').email('Please enter a valid email'),
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid, isDirty }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const processForm = async () => {

        setBlockDiv(true);
        try {
            const res = await callAPI("forgotPassword", getValues());
            
            console.log(res);

            if(res.data.state === 1) { 
              localStorage.setItem('resetemail', getValues().email);
              history('/reset');
            } else if(res.data.state === 2) { //Success
              toast.error('You are banned. Please try after some time.');
              throw res.data.error;
            } else {
              res.data.formError && setFormError(res.data.formError);
              throw(res.data.error);
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        } finally {
          setBlockDiv(false);
        }
        
    }

    return (
      <main className="mb-[60px] md:mb-[75px]">
          <div className="px-4 md:px-11 lg:px-0 max-w-[1024px] mx-auto">
              <div className="py-12 pt-7">
                  <WelcomeTitle
                      title="Forgot Password?"
                      text="Please enter your registered email address below to reset your password."
                  />
              </div>
              <div 
                  className="md:grid grid-cols-2 items-center gap-6 lg:gap-0 space-y-10 md:space-y-0 max-w-md overflow-hidden mx-auto md:mx-0 md:max-w-none relative rounded-[30px] shadow-xl bg-cover border-[#1f1f1f] border-[4px]"
                  style={{ backgroundImage: `url(${loginBg})`, backgroundPosition: 'top center' }}
              >
                  <div className="space-y-10 bg-darkest/95 p-8 shadow-xl">
                      <div className="space-y-4">
                          <form onSubmit={handleSubmit(processForm)}>
                              <FormInputBig type="email" label='Enter your registered email' name='email' placeholder="Email Address" formerror={formError} errors={errors} reg={register} isdirty={isDirty ? 1 : 0} />
                              <LargePrimaryButton type="submit" disabled={!isValid || blockDiv || !appState.captchaVerified} isloading={blockDiv ? 1 : 0}>
                                  <span>Send OTP </span>
                                  <ArrowRightCircle size={25} />
                              </LargePrimaryButton>
                              <p className='text-light text-xs mt-4 text-left'>Remember Password? <Link to={'/login'} className='font-bold text-lighter'>login here</Link></p>
                          </form>
                      </div>
                  </div>
                  <NeedHelp />
              </div>
          </div>
      </main>
    );
};

export default Forgot;
