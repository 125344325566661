import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContestBlock from "../../Components/dash/dashboard/ContestBlock";
import EarnBlock from "../../Components/dash/dashboard/EarnBlock";
import PromoCarousel from "../../Components/dash/dashboard/PromoBanner";
import FooterStats from "../../Components/dash/layout/FooterStats";
import { useUserAuth } from '../../Contexts/authContext';
import EarnImage2 from "../../Theme/assets/img/addImage-2.png";
import EarnImage3 from "../../Theme/assets/img/addImage-3.png";
import EarnImage4 from "../../Theme/assets/img/addImage-4.png";
import EarnImage5 from "../../Theme/assets/img/addImage-5.png";
import EarnImage from "../../Theme/assets/img/addImage.png";
import Contest1 from "../../Theme/assets/img/ct-1.png";
import Contest2 from "../../Theme/assets/img/ct-2.png";
import Contest3 from "../../Theme/assets/img/ct-3.png";
import Contest4 from "../../Theme/assets/img/ct-4.png";
import { TitleBase } from "../../Utility/Utility";
import { useHelpers } from '../../Hooks/helpers';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Alert, Button, Chip, IconButton, Tooltip } from '@material-tailwind/react';
import { AlertCircle, ArrowDownCircle, DollarSign, PlusCircle } from 'react-feather';
import bnb from "../../Theme/assets/img/binance.svg";
import cashImg from "../../Theme/assets/img/cash.png";
import coinflipai from "../../Theme/assets/img/coinflipai.png";
import predictrpoai from "../../Theme/assets/img/predictrpoai.jpeg";
import jackpotai from "../../Theme/assets/img/jackpotai.jpeg";

const earnWay = [
    {
        thumbnail: EarnImage,
        title: "OFFERWALLS",
        text: "Complete diverse tasks and earn cash rewards",
        link: '/offerwalls',
        active: true,
        activeText: 'Earnings active'
    },
    {
        thumbnail: EarnImage2,
        title: "GOALS",
        text: "Complete goals and earn cash rewards",
        link: '/goals',
        active: true,
        activeText: 'Earnings active'
    },
    {
        thumbnail: EarnImage5,
        title: "REFER & EARN",
        text: "Refer friends and family and earn cash rewards",
        link: '/referrals',
        active: true,
        activeText: 'Earnings active'
    },
    {
        thumbnail: EarnImage4,
        title: "JOIN TG GROUPS",
        text: "Earn cash rewards to join telegram groups",
        link: '/jointg',
        active: false
    },
    {
        thumbnail: EarnImage3,
        title: "WATCH VIDEOS",
        text: "Watch youtube videos and earn cash rewards",
        link: '/watchads',
        active: false
    }
];

const contests = [
    {
        thumbnail: coinflipai,
        title: "COIN FLIP PVP",
        text: "A decentralized game where you call heads or tails against another player. Our smart contract ensures fairness, making every toss a thrilling showdown. ",
        link: '/coinflipGame',
        active: true,
        activeText: 'PLAY NOW'
    },
    {
        thumbnail: predictrpoai,
        title: "PREDICT RPO",
        text: "Guess the runs in an over, win the prize pool if you're right. Decentralized, fair, and thrilling – experience cricket predictions like never before!",
        link: '/',
        active: false,
    },
    {
        thumbnail: jackpotai,
        title: "NUMBER POT",
        text: "Pick your lucky number, join the contest, and await the draw. Our decentralized and transparent algorithm ensures a fair winner selection.",
        link: '/',
        active: false,
    },
];


const Dashboard = () => {

    const authContext = useUserAuth();
    const history = useNavigate();
    const helper = useHelpers();
    const auth = useUserAuth();
    
    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === false) {
            history('/login');
        }
    }, [authContext]);

    return (
        <div className="space-y-8">
            <div className="-mr-[36px]">
                <PromoCarousel />
            </div>
            <div>
                <div className="mb-6">
                    <TitleBase>Dashboard</TitleBase>
                </div>
                <div className="grid lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-6">
                    <div className='bg-gradient-one rounded-lg shadow-lg px-7 py-5 pb-7 space-y-4'>
                        <div className='flex justify-between items-center align-middle'>
                            <div className=''>
                                <h3 className='text-xl sm:text-4xl font-ibm-plex-sans font-bold text-darkest flex items-center'>
                                    <helper.AmountToCurrency amount={auth?.userBootstrap?.wallet?.balance_cache_usd} decimals={2}  positive={true} />
                                    <Tooltip content="Your account wallet's equivalent value in BNB"><Chip color="blue-gray" variant='gradient' size="sm" className="ml-[7px]" value={<helper.AmountToCurrency amount={auth?.userBootstrap?.wallet?.balance_cache} prefix="" type='bnb' iconSize={13} decimals={4} />}  positive={true}  /></Tooltip>
                                </h3>
                                <p className='text-sm sm:text-lg'>Account Wallet Balance</p>
                            </div>
                            <div className='space-x-2'>
                                <Tooltip content='Add Funds'><Link to={'/addFunds'}><IconButton color='blue' className="hover:-mt-2 duration-300" variant='gradient'><PlusCircle /></IconButton></Link></Tooltip>
                                <Tooltip content='Withdraw to External Wallet'><Link to={'/withdrawFunds'}><IconButton color='red' className="hover:-mt-2 duration-300" variant='gradient'><ArrowDownCircle /></IconButton></Link></Tooltip>
                            </div>
                        </div>
                        <div className='text-xs px-3 py-2 bg-gradient-seven flex justify-start items-center gap-3 rounded-md text-white'>
                            <AlertCircle className='min-w-[20px]' />
                            Used to participate in decentralised contests and games. Winnings from these contests and games will be credited directly to account your wallet.
                        </div>
                    </div>
                    <div className='bg-blue-800 rounded-lg shadow-lg px-7 py-5 pb-7 space-y-4'>
                        <div className='flex justify-between items-center align-middle'>
                            <div className=''>
                                <h3 className='text-xl sm:text-4xl font-ibm-plex-sans font-bold text-lighter'>
                                    <helper.AmountToCurrency amount={auth?.userBootstrap?.userData?.cash_balance} decimals={3} type='cash' iconSize={35} positive={true} />
                                </h3>
                                <p className='text-sm sm:text-lg text-lighter'>My Cash Balance</p>
                            </div>
                            <div className='space-x-2'>
                                <Tooltip content='Transfer Cash to Account Wallet'><Link to={'/transferCash'}><IconButton color='white' className="hover:-mt-2 duration-300" variant='gradient'><DollarSign /></IconButton></Link></Tooltip>
                            </div>
                        </div>
                        <div className='text-xs px-3 py-2 bg-gradient-eight flex justify-start items-center gap-3 rounded-md text-white'>
                            <AlertCircle className='min-w-[20px]'/>
                            Can be earned from completing tasks from offerwalls, completing goals, referring friends and family and many ways coming soon.
                        </div>
                    </div>
                </div>
            </div>            
            <div>
                <div className="mb-6">
                    <TitleBase>100% Decentralized Blockchain Contests</TitleBase>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 sm:gap-5 2xl:gap-6">
                    {contests.map((item, index) => (
                        <EarnBlock
                        key={index}
                        image={item.thumbnail}
                        title={item.title}
                        text={item.text}
                        link={item.link}
                        active={item.active}
                        activeText={item.activeText}
                        />
                    ))}
                </div>
            </div>
            <div className='pb-5'>
                <div className="mb-6">
                    <TitleBase>Earn Cash Rewards</TitleBase>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-3 sm:gap-5 2xl:gap-6">
                    {earnWay.map((item, index) => (
                        <EarnBlock
                        key={index}
                        image={item.thumbnail}
                        title={item.title}
                        text={item.text}
                        link={item.link}
                        active={item.active}
                        activeText={item.activeText}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
