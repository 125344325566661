import { IonSelectOption } from '@ionic/react';
import { Tooltip } from '@material-tailwind/react';
import { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Clock, Copy, ExternalLink } from 'react-feather';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import countryList from 'react-select-country-list';
import { useUserAuth } from '../Contexts/authContext';
import bnbImg from '../Theme/assets/img/binance.svg';
import cashimg from '../Theme/assets/img/cash.png';
import Timestamp from 'react-timestamp';

export const useHelpers = () => {

    const auth = useUserAuth();

    //Trim an address
    const trimAddress = ({ address, copy = false, scanURL = false, firstShow = 5, secondShow = 3 }) => {
        
        if(address == null) {
            return (<></>);
        }
        const fullAddress = address;

        const short1  = fullAddress.slice(0, firstShow);
        const short2  = fullAddress.slice(fullAddress.length-secondShow, fullAddress.length);
        const trimmedAddr = short1 + '...' + short2;

        if(copy === false && scanURL === false) {
            return trimmedAddr;
        } else {
            return (
                <p className='flex justify-center items-center align-middle'>
                    {trimmedAddr}
                    {copy && <CopyToClipboard onCopy={() => toast.success('Copied!')} text={address}><Copy className='cursor-pointer ml-1 opacity-70 hover:opacity-100' size="13" /></CopyToClipboard>}
                    {scanURL && <a target="_blank" href={scanURL}><Tooltip content='Open Link'><ExternalLink className='cursor-pointer ml-1 opacity-70 hover:opacity-100' size="13" /></Tooltip></a>}
                </p>
            )
        }
        
    }

    //CopyString
    const copyValue = ({ value = false, iconSize = '13' }) => {
        
        if(value == null) {
            return (<></>);
        }
        if(value === false) {
            return value;
        } else {
            return (
                <span className='flex items-center align-middle'>
                    {value}
                    {<CopyToClipboard onCopy={() => toast.success('Copied!')} text={value}><Copy className='cursor-pointer ml-1 opacity-70 hover:opacity-100' size={iconSize} /></CopyToClipboard>}
                </span>
            )
        }
        
    }

    //Convert a amount to readable format
    const AmountToCurrency = ({ amount, decimals = 4, prefix = "$", membershipMultiplier = "none", type = false, iconSize = 20, positive = false }) => {
        
        const renderAmount = () => {
            let rAmount = amount;
            switch (membershipMultiplier) {
                case 'none' || 'base':
                    rAmount = amount;
                    break;

                case 'goal':
                    rAmount = amount*auth?.userBootstrap?.userMembership?.goal_earnings/100;
                    break;

                case 'ad':
                    rAmount = amount*auth?.userBootstrap?.userMembership?.ad_earnings/100;
                    break;

                case 'offerwall':
                    rAmount = amount*auth?.userBootstrap?.userMembership?.offerwall_earnings/100;
                    break;

                case 'purchase':
                    rAmount = amount*auth?.userBootstrap?.userMembership?.purchase_commission/100;
                    break;
            
                default:
                    break;
            }
            if(rAmount*1 <= 0 && positive) {
                return 0;
            } else {
                return rAmount;
            }
        }
        
        return (
            <div className='inline-block'>
                <span className='flex items-center align-middle gap-1'>
                    {type === "bnb" && <img className='' style={{ "width": iconSize }} src={bnbImg} />}
                    <NumericFormat value={renderAmount()} displayType="text" allowLeadingZeros allowNegative={true} prefix={prefix} thousandsGroupStyle="thousand" decimalScale={decimals} />
                    {type === "cash" && <img className='' style={{ "width": iconSize }} src={cashimg} />}
                </span>
            </div>
        );
    }

    //Convert timestamp to data
    const ProcessDate = (timestamp, opString = false ) => {
        const date = new Date(timestamp*1000);
        const humanData = +date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();

        if(opString) {
            return humanData;
        } else {
            return (
                <>
                {humanData}
                </>
            )
        }
    }

    //Convert timestamp to data
    const TimestampAndData = ({created}) => {
        const date = new Date(created*1000);
        const humanData = +date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();

        return (
            <div className='flex items-center gap-1'>
                <Timestamp date={created} options={{ format:"time", includeDay: true }} relativeTo={Date.now()/1000} /> ago<Tooltip content={ProcessDate(created, true)}><Clock size={14} className='text-primary'/></Tooltip>
            </div>
        );
    }

    //Convert timestamp to data by format
    const ProcessDateByFormat = (timestamp, opString = false ) => {
        const humanData = new Date(timestamp*1000).toString().substring(4, 15);
        
        if(opString) {
            return humanData;
        } else {
            return (
                <>
                {humanData}
                </>
            )
        }
    }

    const toCurrency = (val) => {
        return "$" + val;
    }

    const capitalize = (val) => {
        const result = val.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    const processType = (val) => {
        switch (val) {
            case "direct":
                return (<div className='btn btn-success btn-sm'>{capitalize(val)}</div>)
                break;
            case "missed":
                return (<div className='btn btn-danger btn-sm'>{capitalize(val)}</div>)
                break;
            case "gift":
                return (<div className='btn btn-info btn-sm'>{capitalize(val)}</div>)
                break;
            case "fundResidual":
                return (<div className='btn btn-warning btn-sm'>{capitalize(val)}</div>)
                break;
            case "residual":
                return (<div className='btn btn-info btn-sm'>{capitalize(val)}</div>)
                break;
            case "checkmatch":
                return (<div className='btn btn-dark btn-sm'>{capitalize(val)}</div>)
                break;
                                
            default:
                return (<div className='btn btn-warning btn-sm'>{capitalize(val)}</div>)
                break;
        }
    }

    const groupObjArrayByKey = (list, key) => {
        return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {});
    }

    const renderStatusForButton = ({ status }) => {
        let renderButtonObj = {
            classes: '',
            text: ''
        };
        switch (status) {
            //Campaign status
            case "active":
                renderButtonObj = {
                    color: 'green',
                    classes: 'bg-green-600 text-white',
                    text: capitalizeString({ text: status })
                }
                break;
            case "underReview":
                renderButtonObj = {
                    color: 'black',
                    classes: 'bg-gray-900 text-white',
                    text: capitalizeString({ text: status })
                }
                break;
            case "paused":
                renderButtonObj = {
                    color: 'gray',
                    classes: 'bg-yellow-300 text-gray-800',
                    text: capitalizeString({ text: status })
                }
                break;
            case "rejected":
                renderButtonObj = {
                    color: 'red',
                    classes: 'bg-red-500 text-white',
                    text: capitalizeString({ text: status })
                }
                break;
            
            default:
                renderButtonObj = {
                    classes: 'bg-gray-200 text-gray-900',
                    text: capitalizeString({ text: status })
                }
                break;

        }
        return (
            renderButtonObj
        );
    }

    const capitalizeString = ({ text }) => {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    
    //Get the countries list
    const CountriesSelect = ({ showAll = false }) => {
        const countries = useMemo(() => countryList().getData(), []);
        return (
            <>
                {showAll == true &&
                    <IonSelectOption value="all">All Countries</IonSelectOption>
                }
                {countries.length > 0 &&
                    <>
                        {countries.map((el, key) => {
                            return <IonSelectOption key={key} value={el.value}>{el.label}</IonSelectOption>
                        })}
                    </>
                }
            </>
        );
    }

    const groupItemsByData = ( data, keyName ) => {
        if(data.length > 0) {
            const grouped = Object.values(
                data.reduce((a, o) => {
                const day = Math.floor(o[keyName.find((e,k,arr) => o[e] !== null)]/86400);
                    (a[day] ??= []).push({ ...o });
                    return a;
                }, {})
            );
            return grouped.reverse();
        }
        return data;
    }
    


    return {TimestampAndData, groupItemsByData, copyValue, CountriesSelect, capitalizeString, renderStatusForButton, trimAddress, ProcessDate, AmountToCurrency, ProcessDateByFormat, toCurrency, capitalize, processType, groupObjArrayByKey};
    
}

