import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Alert } from '@material-tailwind/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../Utility/Utility';

const AccountSettings = ({ auth }) => {
    
    const defaultValues = {
        email: auth?.userBootstrap?.userData?.email,
        address: auth?.userBootstrap?.wallet?.address,
        referrer_username: auth?.userBootstrap?.userReferrerData?.username,
    };

    const {
        register, formState: { errors, isValid, isDirty }, control
    } = useForm({ defaultValues: defaultValues, mode: 'onChange' })

    return (
        <div>
            <div className=''>
                <FormInput disabled name='email' label="Email Address" helper="Your registered email address" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
            </div>
            <div className='mt-4'>
                <FormInput disabled name='referrer_username' label="Referrer" helper="Your referrer's username" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
            </div>
            <div className='mt-4'>
                <FormInput disabled={true} name='address' label="Wallet Address" helper="Your registered wallet address" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
            </div>
        </div>
    );
};
export default AccountSettings;