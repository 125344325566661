import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { Pause } from 'react-feather';
import { useHelpers } from '../../../Hooks/helpers';

export const SurfAdCounter = ({ timer, counterHook }) => {

    const helper = useHelpers();

    const countDownAPI = useRef(null);
    const [startTimer,] = useState(Date.now() + timer*1000);

    const counterRunning = (e) => {
        counterHook.setSeconds(e.total);
        counterHook.setPercent(1-(e.total/1000)/timer);
    }

    //Pause/unpause counter from hook
    useEffect(() => {
        if(counterHook.isPaused === null) return;
        if(counterHook.isPaused === true) {
            countDownAPI.current.pause();
        } else {
            countDownAPI.current.start();
        }
    }, [counterHook.isPaused]);

    return (
        <>
            <Countdown date={startTimer} renderer={ () => {return true} } controlled={false} ref={countDownAPI} onTick={counterRunning} onStart={() => counterHook.setIsPaused(false)} onComplete={() => counterHook.setEnded(true)} autoStart={false} />
            {counterHook.isPaused === false && <div className='text-yellow-900 font-bold text-[23px]'><helper.AmountToCurrency amount={counterHook.seconds/1000} decimals={0} prefix='' /></div>}
            {(counterHook.isPaused === true || counterHook.isPaused === null) && <Pause size={23} />}
        </>
    )
}
