import { Button } from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";

export const BaseButton = ({ children, ...props }) => {
  return (
    <Button {...props}>
      {!props.isloading ?
        <>{children}</>
      :
        <>
          <span>{props.loadingText ? props.loadingText : 'Loading'} </span>
          <ClipLoader
            color={props.loadercolor}
            loading={true}
            size={props.loadersize ? props.loadersize : 15}
            aria-label="Loading"
            data-testid="loader"
          />
        </>
      }
    </Button>
  );
}

export const LargeOutlineButton = ({ children, ...props }) => {
    return (
      <BaseButton
        {...props}
        loadercolor="#fff"
        className={`border-2 border-primary flex items-center justify-between rounded-10 normal-case text-lg bg-transparent text-white font-ibm-plex-sans font-semibold px-6 py-5 disabled:opacity-50 ${props.isloading ? 'opacity-50 pointer-events-none' : ''}`}
      >
        {children}
      </BaseButton>
    );
}

export const LargePrimaryButton = ({ children, ...props }) => {

    return (
      <BaseButton
        {...props}
        className={`w-full bg-gradient-one flex items-center justify-between rounded-10 text-lg normal-case text-black font-ibm-plex-sans font-semibold px-6 py-5 disabled:opacity-50 hover:bg-gradient-six  ${props.isloading ? 'opacity-50 pointer-events-none' : ''}`}
      >
        {children}
      </BaseButton>
    );
}

export const MediumDarkButton = ({ children, ...props }) => {

  return (
    <BaseButton
      {...props}
      loadercolor="#fff"
      loadersize="20"
      className={`bg-[#1C2731] rounded-10 flex items-center justify-between uppercase pl-6 pr-4 h-[54px] text-white transition w-full text-sm font-ibm-plex-sans font-bold disabled:opacity-50 ${props.isloading ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {children}
    </BaseButton>
  );
}

export const SmallPrimaryButton = ({ children, ...props }) => {

  return (
    <BaseButton
      {...props}
      className={`normal-case bg-gradient-one rounded-10 flex items-center justify-between pl-5 pr-4 h-[45px] text-black w-full text-sm font-ibm-plex-sans font-semibold disabled:opacity-50 hover:bg-gradient-six transition ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {children}
    </BaseButton>
  );
}