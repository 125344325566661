import React from "react";
import { Link } from "react-router-dom";

const EarnBlk = ({ image, title, text, link, active, activeText }) => {
  return (
    <Link to={active ? link : null}>
      <div className={`relative rounded-lg shadow-lg overflow-hidden basis-[calc(25%_-_103px)] hover:-mt-2 duration-300 ${active ? 'opacity-1000' : 'opacity-60 pointer-events-none'}`}>
        <div className={`text-center px-4 py-2 text-xs font-bold uppercase font-viga text-lighter ${active ? 'bg-gradient-seven' : 'bg-gray-900'}`}>
          {active ? activeText : 'Coming Soon'}
        </div>
        <img src={image} alt="earn-img" className="w-full" />
        <div className="absolute start-0 end-0 bottom-0 bg-gradient-four p-4 text-center">
          <h5 className="text-lg text-white font-viga font-bold mb-1 leading-tight uppercase">
            {title}
          </h5>
          <p className="text-white/70 text-sm leading-5 font-ibm-plex-sans">{text}</p>
        </div>
      </div>
    </Link>
  );
};

export default EarnBlk;
