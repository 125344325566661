import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import ClipLoader from 'react-spinners/ClipLoader';

export const SurfAdCaptcha = ({ ad, captchaHook, counterHook, videoHook }) => {
    
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [currentView, setCurrentView] = useState(false); //pre, select, post
    const [captchaStatus, setCaptchaStatus] = useState({title: "Processing..", icon: <ClipLoader
            color={'#777'}
            loading={true}
            size={25}
            aria-label="Loading"
            data-testid="loader"
            className="w-6 mx-auto"
        />
    });

    //Trigger captcha popper modal
    useEffect(() => {
        if(!currentView && counterHook.percent*100 > ad.showCaptchaAt && ad.showCaptcha === "yes") {
            setShowCaptcha(true);
            setCurrentView('pre');
            pauseAd(true);
            setTimeout(() => {
                setCurrentView('select');
            }, 5000);
        }
    }, [counterHook.percent]);

    useEffect(() => {
        if(captchaHook.dismissCaptcha === true) {
            setShowCaptcha(false);
            setCurrentView(true);
        }
    }, [captchaHook.dismissCaptcha]);

    const pauseAd = (state) => {
        counterHook.setIsPaused(state);
        videoHook.setVideoPaused(state);
    }

    const validateCaptcha = (key) => {
        setCurrentView("post");
        if(key === ad.correctCaptcha) {
            captchaHook.setCaptchaSolved(true);
            setCaptchaStatus({title: "Verification Successful!", icon: <CheckCircleIcon className='text-green-600 mx-auto w-12 h-12' />});
        } else {
            setCaptchaStatus({title: "Wrong Captcha!", icon: <XCircleIcon className='text-red-600 mx-auto w-12 h-12' />});
        }
        setTimeout(() => {
            setShowCaptcha(false);
            setCurrentView(true);
            pauseAd(false);
        }, 2000);
    }

    return (
        <Transition appear show={showCaptcha} as={Fragment}>
            <Dialog as="div" static={true} onClose={() => null} className="relative z-10">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-40" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            {currentView === 'pre' && 
                                <div className='text-center'>
                                    <h3 className='text-lg font-bold mb-3'>Captcha Verification</h3>
                                    {captchaHook.captchas[ad.correctCaptcha]}
                                    <p className='text-xsm mt-3 opacity-70'>Challenge will be shown in <Countdown date={Date.now() + 5000} intervalDelay={0} precision={0} renderer={props => <>{props.total/1000}</>} /></p>
                                </div>
                            }
                            {currentView === 'select' && 
                                <div className='text-center'>
                                    <h3 className='text-lg font-bold mb-3'>Complete the challange to continue</h3>
                                    {captchaHook.captchas && captchaHook.captchas.map((item, key) => {
                                        return (
                                            <span key={key} onClick={() => validateCaptcha(key)}>{item}</span>
                                        );
                                    })}
                                    <p className='text-xsm mt-3'>Choose image shown previously to verify this ad.</p>
                                </div>
                            }
                            {currentView === 'post' && 
                                <div className='text-center'>
                                    <h3 className='text-lg font-bold mb-3'>{captchaStatus.title}</h3>
                                    {captchaStatus.icon}
                                    <p className='text-xsm mt-3 opacity-70'>Ad will continue to play in <Countdown date={Date.now() + 2000} intervalDelay={0} precision={0} renderer={props => <>{props.total/1000}</>} /> seconds.</p>
                                </div>
                            }
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}