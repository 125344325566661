import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";
import "@fontsource/viga";
import '@ionic/react/css/core.css';
import { ThemeProvider } from "@material-tailwind/react";
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import 'react-spring-bottom-sheet/dist/style.css';
import "react-toggle/style.css";
import App from './App';
import appConfig from "./Utility/Config/config";
import "./index.css";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(appConfig.gaCode);
root.render(
    <ThemeProvider>
      <GoogleReCaptchaProvider reCaptchaKey={appConfig.reCaptchaKey}>
        <App />
      </GoogleReCaptchaProvider>
    </ThemeProvider>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);