import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Alert, Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { ArrowUpRight } from 'react-feather';
import QRCode from "react-qr-code";
import { formatEther } from "viem";
import { useUserAuth } from "../../Contexts/authContext";
import { useHelpers } from "../../Hooks/helpers";
import { MediumDarkButton } from '../../Utility/Buttons';
import appConfig from "../../Utility/Config/config";
import { TitleBase } from "../../Utility/Utility";

const AddFunds = () => {
    
    const auth = useUserAuth();
    const helper = useHelpers();

    const [bnbToSend, setBnbToSend] = useState(0);

    const getBNBToSend = async (e) => {
        setBnbToSend(e.target.value*auth?.systemSettings.ONE_USD_WEI);
    }

    return (
        <>
            <div>
                <div className="mb-7">
                    <TitleBase>Add Funds to your Wallet</TitleBase>
                    <div className="bg-gradient-seven rounded-10 shadow-md px-4 py-3 mt-4 text-lighter font-ibm-plex-sans text-sm flex items-center gap-3">
                        <InformationCircleIcon className="min-w-[30px] max-w-[30px]" />
                        <div>Enter amount you wish to add and send equivalent BNB to your wallet address mentioned below. Your wallet's BNB balance will automatically update once BNB is received in your wallet or you can refresh it manually by <b className="cursor-pointer" onClick={auth?.getAppBootstrap}>clicking here</b>.</div>
                    </div>
                </div>
                <div className="mb-3 mx-auto bg-gradient-one shadow-xl rounded-10 p-8 font-ibm-plex-sans">
                    <div className="text-center">
                        <span className="text-darker font-ibm-plex-sans font-medium text-md leading-tight block">
                            Enter USD you wish to add
                        </span>
                        <input type="number" placeholder="$0.00" onChange={getBNBToSend} className="text-[#2b2c2a] placeholder-[#727a63] bg-transparent w-full text-center font-bold text-5xl mt-2 mb-2 focus:outline-none" />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-[30%_70%] md:space-x-7 space-y-7 md:space-y-0 align-top mt-5'>
                        <div>
                            <div className="text-center p-4 bg-white rounded-10 shadow-md mx-auto max-w-[300px]">
                                <QRCode value={auth?.userBootstrap?.wallet?.address} className="mx-auto w-full" />
                                <p className="text-xs mt-2 text-dark leading-[15px]">Scan using any wallet app to load your wallet address and pay</p>
                            </div>
                        </div>
                        <div className="text-left">
                            <div className="grid grid-cols-2 gap-5">
                                <div className="p-3 bg-white rounded-10 shadow-md">
                                    <p className="text-sm text-dark">BNB to send</p>
                                    <p className="font-bold text-xs sm:text-lg"><helper.copyValue value={formatEther(bnbToSend)} /></p>
                                </div>
                                <div className="p-3 bg-white rounded-10 shadow-md">
                                    <p className="text-xs sm:text-md text-dark">Network</p>
                                    <p className="font-bold text-xs md:text-lg">Binance Smart Chain</p>
                                </div>
                            </div>
                            <div className="p-3 bg-white rounded-10 shadow-md mt-5">
                                <p className="text-sm text-dark">Send to wallet Address</p>
                                <p className="font-bold text-xs md:text-lg"><helper.copyValue value={auth?.userBootstrap?.wallet?.address} /></p>
                            </div>
                            <Alert className="text-xs leading-tight p-2 px-3 mt-4 shadow-md" color="blue" variant='gradient'>Make sure to send only <b>BNB</b> to the above wallet via <b>Binance Smart Chain</b>. Funds sent via any other chain will become impossible to recover or a 20% recovery fee will be implied if recoverable.</Alert>
                            <div className="mt-4">
                                <a href={appConfig.scanAddressLink + auth?.userBootstrap?.wallet?.address} target="blank"><Button color="black" variant='outlined' className="flex items-center justify-between gap-3 w-full">View Wallet Activity on Blockchain<ArrowUpRight /></Button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddFunds;