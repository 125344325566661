import { Tooltip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { LoadingData, SVGLighting } from "../../../Utility/Utility";
import { BarLoader } from "react-spinners";

const GameWidget = ({ isloading, entry, win, highlight }) => {

  return (
    <div className={`grid grid-cols-2 rounded-10 relative slide_item mr-4 md:mr-6 ${isloading ? 'opacity-55' : ''} hover:-mt-1 duration-300`}>
      {highlight && (
        <Tooltip content='PRO Only'>
          <span className="absolute w-8 h-8 rounded-full bg-white -right-3 -top-3 flex items-center justify-center">
            <SVGLighting width="23" height="23" stroke="#232323" />
          </span> 
        </Tooltip>
      )}
      <div className="bg-[#343143] flex flex-col items-center justify-center py-3 rounded-tl-10 rounded-bl-10">
        <p className="text-xs font-ibm-plex-sans font-medium text-white">
          Entry
        </p>
        <strong className="text-2xl font-ibm-plex-sans font-bold leading-tight text-[#F8C571] block">
          ${entry}
        </strong>
      </div>
      <div className="bg-[#F8C571] flex flex-col items-center justify-center py-3 rounded-tr-10 rounded-br-10">
        <p className="text-xs font-ibm-plex-sans font-medium text-[#2C2C2C]">
          Win
        </p>
        <strong className="text-2xl font-ibm-plex-sans font-bold leading-tight text-[#2C2C2C] block">
          {win}
        </strong>
      </div>
    </div>
  );
};

const GamewidgetCarousel = ({ openFirstContest }) => {

  const settings = {
    infinite: false,
    speed: 800,
    slidesToShow: 0,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2400,
    centerMode: false,
    centerPadding: 100,
    nextArrow: "",
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6.2,
          centerPadding: 130,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4.2,
          centerPadding: 150,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.2,
          centerPadding: 80,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2.3,
          centerPadding: 30,
        },
      },
    ],
  };

  const callAPI = useCallAPI();
  const history = useNavigate();
  const auth = useUserAuth();
  const helpers = useHelpers();

  const [presets, setPresets] = useState([]);
  const [presetLoading, setPresetLoading] = useState(false);
  
  useEffect(() => {
      if(auth.appBootstrapped === true && auth.loggedIn === true) {
          getPresets();
      }
  }, [auth]);

  const getPresets = async () => {
    const res = await callAPI("getCoinflipGamePresets");      
    if(res.status && res.data) {
      setPresets(res.data);
      openFirstContest && openContest(res.data[0].id);
    } else {
      setPresets(false);
    }
  }

  const openContest = async (id) => {
    //Get latest contest for this preset from blockchain
    try {
      setPresetLoading(id);
      const res = await callAPI("readCoinflipCA/presetContests/" + id);
      if(res.status && !res.data.error) {
        history('/coinflipGame/' + res.data.result.lastCreatedId);
      } else {
        throw(res.data.error);
      }
    } catch (e) {
      toast.error(e)
    } finally {
      setPresetLoading(false);
    }
    
  }

  return (
    <>
      {presets && presets.length > 0 ?
        <Slider {...settings} className="game_carousel h-[100px]">
          {presets.map((e, k) => {
              return (
                <div key={k} onClick={() => openContest(e.id)}>
                    <GameWidget isloading={presetLoading === e.id} highlight={e.proOnly === 'yes' ? true : false} entry={e.entryFee} win={e.takeFee === 'yes' ? helpers.AmountToCurrency({ amount: (e.entryFee*2-(e.entryFee*2*auth.systemSettings.COINFLIP_ADMIN_FEE/100)), decimals: 1 }) : helpers.AmountToCurrency({ amount: e.entryFee*2, decimals: 0 })} />
                </div>
              )
          })}
        </Slider>
      :
        <div className="flex justify-center align-middle my-[50px] space-x-5 mr-10">
          <BarLoader
            color='#F8C571'
            loading={true}
            size={10}
            aria-label="Loading"
            data-testid="loader"
          />
        </div>
      }
    </>
  );
};

export default GamewidgetCarousel;
