import AccountSettings from "../../Components/dash/settings/AccountSettings";
import ChangeProfilePic from "../../Components/dash/settings/ChangeProfilePic";
import ProfileSettings from "../../Components/dash/settings/ProfileSettings";
import VerifyEmail from "../../Components/dash/settings/VerifyEmail";
import { useUserAuth } from "../../Contexts/authContext";
import { FormBlock, TitleBase } from "../../Utility/Utility";

const Settings = () => {

    const auth = useUserAuth();
    
    return (
        <>
            <div className="space-y-3 sm:flex justify-between items-center">
                <div className='space-y-3 '>
                    <TitleBase>Settings</TitleBase>
                </div>
            </div>
            <div className="">
                <div className='md:grid grid-cols-2 sm:gap-x-5'>
                    <div className="">
                        {auth?.userBootstrap?.userData?.email_verified === 'no' &&
                            <FormBlock heading='VERIFY EMAIL ADDRESS'>
                                <VerifyEmail auth={auth} />
                            </FormBlock>
                        }
                        <FormBlock heading='USER DETAILS'>
                            <ProfileSettings auth={auth} />
                        </FormBlock>
                        <FormBlock heading='CHANGE PROFILE PIC'>
                            <ChangeProfilePic auth={auth} />
                        </FormBlock>
                    </div>
                    <div className={`${auth?.userBootstrap?.userData?.profile_completed === "yes" ? '' : 'opacity-50 cursor-wait pointer-events-none'}`}>
                        <FormBlock heading='ACCOUNT DETAILS'>
                            <AccountSettings auth={auth} />
                        </FormBlock>
                        {/* <FormBlock heading='2FA SETUP'>

                        </FormBlock> */}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Settings;