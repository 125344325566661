import React from "react";
import { Link, useLocation } from "react-router-dom";
import loginBg from "../Theme/assets/img/login-bg.png";
import logo from "../Theme/assets/img/logo-full-black.png";
import signInIcon from "../Theme/assets/img/sign_in_circle.svg";
import AppRouter from "./AppRouter";

export default function AuthLayout() {  

  const location = useLocation();

  return (
    <div
      className="min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${loginBg})` }}
    >
      <header className="px-4 py-6 sm:py-6 sm:px-5 lg:px-20 lg:py-10">
        <div className="grid grid-cols-[80px_1fr] sm:flex sm:justify-between items-center">
          <div className="text-center col-span-3 mb-2 sm:hidden">
            <Link to="/" className="inline-block">
              <img src={logo} alt="Logo" className="w-[135px]" />
            </Link>
          </div>
          <div className="flex items-center">
            <Link
              to="/"
              className="link-primary-onhover flex items-center text-white text-base font-ibm-plex-sans font-normal sm:mr-7 md:mr-10 hover:text-primary transition duration-200"
            >
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2 sm:mr-2.5"
              >
                <path
                  className="transition duration-200"
                  d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
                  fill="white "
                />
              </svg>
              <span className="hidden">Back to</span> home
            </Link>
            <Link to="/" className="hidden sm:block">
              <img src={logo} alt="Logo" className="w-[135px]" />
            </Link>
          </div>

          <div className="text-end">
            {location.pathname === '/login' && 
              <p className="flex items-center justify-end text-white text-base font-ibm-plex-sans font-normal">
                <img src={signInIcon} alt="Icon" className="mr-2 sm:mr-2.5" />
                New to DexLoot?
                <Link
                  to="/register"
                  className="ml-1 hover:text-primary transition duration-200"
                >
                  Register now
                </Link>
              </p>
            }
            {location.pathname === '/register' && 
              <p className="flex items-center justify-end text-white text-base font-ibm-plex-sans font-normal">
                <img src={signInIcon} alt="Icon" className="mr-2 sm:mr-2.5" />
                Already registered?
                <Link
                  to="/login"
                  className="ml-1 hover:text-primary transition duration-200"
                >
                  Login now
                </Link>
              </p>
            }
          </div>
        </div>
      </header>

      <div className="min-h-[calc(100vh_-_134px)] flex flex-col justify-center">
        <AppRouter />
      </div>
    </div>
  );
}