import { useState } from 'react';

export const useAppModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [backdropDismiss, setBackdropDismiss] = useState(true);
    const [scrollLocking, setScrollLocking] = useState(true);
    const [showDragHeader, setShowDragHeader] = useState(true);
    const [headerContent, setHeaderContent] = useState(null);
    const [fullHeight, setFullHeight] = useState(false);

    const open = (backdropDismiss = true, scrollLocking = true, showDragHeader = true, headerContent = null, fullHeight = false ) => {
        setBackdropDismiss(backdropDismiss);
        setScrollLocking(scrollLocking);
        setShowDragHeader(showDragHeader);
        setHeaderContent(headerContent);
        setFullHeight(fullHeight);
        setIsOpen(true);
    }

    const close = () => {
        setIsOpen(false);
    }

    return {
        isOpen,  //state
        open,
        close,
        backdropDismiss,
        scrollLocking,
        showDragHeader,
        headerContent,
        fullHeight
    };
}
