import { CheckBadgeIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button } from "@material-tailwind/react";
import { useState } from "react";
import { AlertCircle, ArrowRightCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import { PuffLoader } from "react-spinners";
import { formatEther } from 'viem';
import * as Yup from 'yup';
import WithdrawalsHistory from "../../Components/dash/withdrawals/WithdrawalsHistory";
import { InfoModal } from "../../Components/modals/InfoModal";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { useInfoModal } from "../../Hooks/infoModal";
import { usePollAPI } from "../../Hooks/pollAPI";
import { MediumDarkButton } from '../../Utility/Buttons';
import { TitleBase } from "../../Utility/Utility";

const WithdrawFunds = () => {
    
    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const infoModal = useInfoModal();
    const pollResults = usePollAPI();

    const [blockDiv, setBlockDiv] = useState(false);
    const [formError, setFormError] = useState({});

    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .required('Amount is required')
            .typeError('Amount must be a number')
            .min(auth?.systemSettings.WITHDRAWALS_MINIMUM_USD*1)
            .max((Math.round(auth?.userBootstrap?.wallet?.balance_cache_usd * 100) / 100) + 0.01, "Amount must be less than or equal to your wallet balance")
            .label('Amount')
            .nullable(),
        address: Yup.string()
            .required('Please enter your BNB receiving wallet (BSC Chain)')
            .label('Wallet Address')
            .min(42).max(42).nullable(),
    });

    const {
        register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
    } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) })

    const onSubmit = async () => {

        setBlockDiv(true);
        const formData = getValues();

        try {
            const res = await callAPI("createWithdraw", formData);
            console.log(res);
            if(res.status && res.data.state*1 === 1) {
                //Check if instant withdrwaals enabled, Else show withdraw reqest received
                if(auth?.systemSettings.WITHDRAWALS_INSTANT_PROCESS === 'yes') {
                    infoModal.open({
                        title: "Withdrawal In Progress", 
                        description: 'Your request is in progress and will be credited to your external address in 1-2 minutes.',
                        icon: <PuffLoader
                            color={'#F1BE6B'}
                            loading={true}
                            size={70}
                            aria-label="Loading"
                            data-testid="loader"
                            className="mx-auto mb-5"
                        />,
                        showConfeti: false,
                        hideButton: true,
                        backdropDND: true
                    });
                    const oracleStatus = await checkOracleCompleted(res.data.rowId);
                    infoModal.close();
                    if(oracleStatus === 'complete') {
                        infoModal.open({
                            title: "Withdrawal Complete", 
                            description: 'Your request is processed successfully! Your withdraw amount is sent to your external wallet.',
                            icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                            showConfeti: true
                        });
                        await auth.getAppBootstrap();
                    }
                } else {
                    infoModal.open({
                        title: "Withdrawal request received", 
                        description: 'Your request is in progress and will be credited to your account wallet in 24-48 hours.',
                        icon: <CheckBadgeIcon className='w-20 mx-auto mb-5 text-amber-700 animate-bounce' />,
                        showConfeti: false
                    });
                }
            } else {
                throw(res.data.error);
            }
        } catch (e) {
            toast.error(e);
        } finally {
            setBlockDiv(false);
        }
    }

    const checkOracleCompleted = (id) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkWithdrawalTxn/" + id);
                const pollContinueCondition = (res) => res.data.state === "pending" || res.data.state === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                if(pollRes.data.state == "failed") {
                    toast.error("Failed to withdraw. Contact support for more info.");
                }
                resolve(pollRes.data.state);
             })();
        });
    }

    return (
        <>
            <div>
                <div className="mb-7">
                    <TitleBase>Withdraw Funds to your External Wallet</TitleBase>
                    <div className="bg-gradient-seven rounded-10 shadow-md px-4 py-3 mt-4 text-lighter font-ibm-plex-sans text-sm flex items-center gap-3">
                        <InformationCircleIcon className="min-w-[30px] max-w-[30px]" />
                        <div>Withdraw funds from your account's wallet to your personal external BNB receiving wallet. Enter amount in BNB you wish to withdraw and enter your BSC chain BNB receiving wallet address.</div>
                    </div>
                </div>
                <div className="mb-3 bg-gradient-one shadow-xl rounded-10 p-8 font-ibm-plex-sans max-w-xl mx-auto">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="text-center">
                            <span className="text-darker font-ibm-plex-sans font-medium text-md leading-tight block">
                                Enter USD to withdraw
                            </span>
                            <input type="text" name="amount" {...register('amount')} placeholder="$0.00" className="text-[#2b2c2a] placeholder-[#727a63] bg-transparent w-full text-center font-bold text-5xl mt-2 mb-1 focus:outline-none" />
                            {errors.amount?.message && <p className="text-white bg-red-900 inline-block mx-auto text-xsm mb-3 px-3 py-1 rounded-[5px]">{errors.amount?.message}</p>}
                            {formError.amount && <p className="text-red-400 text-xsm mt-2 text-right">{formError.amount}</p>}

                            <p className="text-sm text-darkest"><helper.AmountToCurrency amount={formatEther(getValues('amount')*auth?.systemSettings.ONE_USD_WEI)} prefix="" decimals={5} /> BNB</p>
                            <Button color="blue" size="sm" className="mt-2" onClick={() => setValue('amount', auth?.userBootstrap?.wallet?.balance_cache_usd*1, { shouldDirty: true, shouldTouch: true, shouldValidate: true })}>Withdraw Max</Button>
                            
                            <span className="text-darker font-ibm-plex-sans font-medium text-md leading-tight block mt-7">
                                Enter your BSC Chain BNB receiving address
                            </span>
                            <input type="text" placeholder="0x511430e79e5C9b0D04C1B4208B67A5fC254F6Abc" name="address" {...register('address')} className="text-[#2b2c2a] placeholder-[#727a63] bg-transparent border-2 border-dark/30 py-3 rounded-10 w-full text-center font-bold text-sm sm:text-lg mt-4 focus:outline-none" />
                            {errors.address?.message && <p className="text-white bg-red-900 inline-block mx-auto text-xsm mt-3 px-3 py-1 rounded-[5px]">{errors.address?.message}</p>}
                            {formError.address && <p className="text-red-400 text-xsm mt-2 text-right">{formError.address}</p>}

                            <div className='mt-4'>
                                <MediumDarkButton type='submit' disabled={!isValid || blockDiv} isloading={blockDiv ? 1 : 0}>
                                    Withdraw Now
                                    <ArrowRightCircle size={25} />
                                </MediumDarkButton>
                            </div>
                            <Alert icon={<AlertCircle color="#444" />} className="text-xs text-left leading-tight p-2 px-3 mt-4 shadow-md items-center" color="amber" variant='gradient'>A very small gas fee will be deducted from the BNB amount you withdraw. The gas amount is used to process your withdrawal on the blockchain and is not collected by DexLoot.</Alert>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <div className="my-5">
                    <TitleBase>Withdrawals History</TitleBase>
                </div>
                <WithdrawalsHistory />
            </div>
            <InfoModal hook={infoModal} />
        </>
    );
};
export default WithdrawFunds;