import { useState } from "react";
import MyReferrals from "../../Components/dash/referrals/MyReferrals";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { useInfoModal } from "../../Hooks/infoModal";
import { usePollAPI } from "../../Hooks/pollAPI";
import { TitleBase } from "../../Utility/Utility";
import refImg from "../../Theme/assets/img/referrals.png";

const Referrals = () => {
    
    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
    const infoModal = useInfoModal();

    const [blockDiv, setBlockDiv] = useState(false);

    return (
        <>
            <div className="mb-3">
                <div className='rounded-10'>
                    <div className="bg-gradient-1 shadow-2xl rounded-[25px] p-8 pb-5 text-center max-w-3xl mx-auto font-ibm-plex-sans">
                        <h5 className="text-darkest font-viga text-2xl sm:text-4xl">Refer & Earn</h5>
                        <p className="text-dark text-sm sm:text-lg">Refer your friends and family to DexLoot and Earn Unlimited Bonuses</p>
                        <div className="my-4 border-2 border-dark inline-block px-3 sm:px-10 py-3 rounded-2xl">
                            <h3 className="text-sm sm:text-md text-dark">My Unique Referral Link</h3>
                            <p className="font-bold text-darker text-xs sm:text-lg flex justify-center"><helper.copyValue value={auth?.userBootstrap.userData.userRefLink} /></p>
                        </div>
                        <img src={refImg} className="-mb-[45px]" />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 items-start align-top justify-center max-w-3xl mx-auto gap-4 mt-[60px]">
                        <div className="bg-gradient-one px-4 py-3 rounded-xl shadow-lg">
                            <h1 className="font-viga text-md sm:text-2xl">5% Bonus</h1>
                            <p className="text-sm text-darkest">from your referrals when they join games and contests. Example : 100 Referrals join $10 coinflip contest. You earn <b>$50</b>.</p>
                        </div>
                        <div className="bg-gradient-one px-4 py-3 rounded-xl shadow-lg">
                            <h1 className="font-viga text-md sm:text-2xl">Cash Bonuses</h1>
                            <p className="text-sm text-darkest">when you complete referrals related goals. Check Goals page for referrals related goals. Complete them and earn rewards.</p>
                        </div>
                        <div className="bg-gradient-one px-4 py-3 rounded-xl shadow-lg">
                            <h1 className="font-viga text-md sm:text-2xl">More Bonuses</h1>
                            <p className="text-sm text-darkest">from various earning ways and decentralised games. Keep an eye here to see all the benefits coming soon.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="my-5">
                    <TitleBase>My Referrals</TitleBase>
                </div>
                <MyReferrals />
            </div>
        </>
    );
};
export default Referrals;