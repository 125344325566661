import { Button, Typography } from '@material-tailwind/react';
import React from 'react';
import Countdown from 'react-countdown';
import { useHelpers } from '../../../Hooks/helpers';

const OfferwallBlock = ({ wall }) => {

    const helper = useHelpers();

    const processTags = (e) => {
        return (
            <>
                {e.tags !== null && JSON.parse(e.tags).map((e, i) => {
                    return (
                        <Button autoFocus={false} size='sm' key={i} color={e.color}>{e.value}</Button>
                    );
                })}
            </>
        );
    }

    return (
        <>
            {wall &&
                <div className='bg-gradient-seven rounded-10 w-full overflow-hidden shadow-md hover:-mt-1 duration-300'>
                    <div className='py-7 bg-white items-center text-center shadow-lg'>
                        <img src={wall.logo} className='h-[40px] mx-auto' />
                    </div>
                    {wall.offer > 0 && wall.offerExpire*1000 > Date.now() &&
                        <div className='bg-gradient-one flex justify-between px-4 py-2'>
                            <p className='text-xs flex items-center leading-[13px]'><span className='font-bold text-lg mr-2'><helper.renderNumber amount={wall.offer} decimals={0} prefix="+" suffix={"%"} /></span>Multiplier<br/>Active</p>
                            <p className='text-xs leading-[10px] text-right'><span  className='font-bold text-sm block'><Countdown date={wall.offerExpire*1000} /></span>Expires in</p>
                        </div>
                    }
                    <div className='flex justify-between items-center px-5 py-4 text-white font-bold'>
                        <div className=''>
                            <Typography variant="h6">{wall.name}</Typography>
                            <p className='text-gray-300 font-normal text-xs'>Earned <helper.AmountToCurrency amount={wall.earned} decimals={2} /></p>
                        </div>
                        <div className=''>
                            {processTags(wall)}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default OfferwallBlock;