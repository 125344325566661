import { useState } from 'react';
import { Anchor, BatteryCharging, Coffee, Key } from 'react-feather';

export const useSurfAdCaptcha = () => {
    
    const [captchas,] = useState([
        <button className="bg-blue-700 p-[8px] m-1 rounded-[8px] hover:bg-blue-900 text-white"><Anchor size={20} /></button>,
        <button className="bg-green-700 p-[8px] m-1 rounded-[8px] hover:bg-green-900 text-white"><BatteryCharging size={20} /></button>,
        <button className="bg-red-700 p-[8px] m-1 rounded-[8px] hover:bg-red-900 text-white"><Coffee size={20} /></button>,
        <button className="bg-indigo-700 p-[8px] m-1 rounded-[8px] hover:bg-indigo-900 text-white"><Key size={20} /></button>
    ]);
    const [dismissCaptcha, setDismissCaptcha] = useState(null);
    const [captchaSolved, setCaptchaSolved] = useState(false);
    
    return { 
        captchas,
        dismissCaptcha,
        captchaSolved,
        setDismissCaptcha,
        setCaptchaSolved
    };
    
}