import React, { useEffect, useRef } from 'react';
import { PlayCircle } from 'react-feather';

const SurfAdVideoBox = ({ video, videoHook, counterHook }) => {

    const vidRef = useRef(null);
  
    useEffect(() => {
        if (!videoHook.videoPaused) {
            vidRef.current.play();
        } else {
            vidRef.current.pause();
        }
    }, [videoHook.videoPaused]);

    const togglePlayPause = () => {
        videoHook.setVideoPaused(!videoHook.videoPaused);
    };

    return (
        <div className='videoContainer'>
            {vidRef?.current?.paused && 
                <div className='videoControl' onClick={togglePlayPause}>
                    <PlayCircle size="40" />
                </div>
            }
            <video
            style={{ "background":"#000" }}
            onClick={togglePlayPause}
            onWaiting={() => counterHook.setIsPaused(true)}
            onPlaying={() => counterHook.setIsPaused(false)}
            onPause={() => counterHook.setIsPaused(true)}
            loop={false}
            ref={vidRef}
            playsInline
            autoPlay
            width={"100%"}
            height={"100%"}
            controlsList="play">        
                <source src={video} />
                Error in rendering ad
            </video>
        </div>
        
    );

}
export default SurfAdVideoBox;
