import React from 'react';
import Iframe from 'react-iframe';

const SurfAdIFrameBox = ({ media, counterHook }) => {

    const loadComplete = () => {
        counterHook && counterHook.setIsPaused(false);
    }

    return (
        <Iframe 
            url={media}
            onLoad={loadComplete}
            width="100%"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
            height="100%"
            frameBorder={0}
            id=""
            className="z-[1]"
            display="block"
            position="relative" 
        />
        
    );

}
export default SurfAdIFrameBox;
