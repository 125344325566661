import React from "react";
import { AdTimeCredit, AddWatchSchedule } from "../../../Utility/Utility";
import { useHelpers } from "../../../Hooks/helpers";
const AdBlockSmall = ({ ad }) => {
  const helper = useHelpers();
  return (
    <div>
      <div className="bg-gradient-eight rounded-10 px-5 py-4 shadow-[0_4px_4px_0px_rgb(0,0,0,0.15)] ">
        <h5 className="text-md font-ibm-plex-sans text-gray-300	leading-tight mb-4 font-semibold ">
          {ad.title}
        </h5>
        <AdTimeCredit time={ad.timer} credit={helper.AmountToCurrency({ amount: ad.cpv, membershipMultiplier: 'ad', decimals: 4 })} />
      </div>
      <div className="relative pb-[20px]">
        <div className="absolute top-0  left-5">
          <AddWatchSchedule text={"Watch every "+ ad.frequency + " hours"} />
        </div>
      </div>
    </div>
  );
};

export default AdBlockSmall;
